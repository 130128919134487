/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react'
import styled from '@emotion/styled'
import { Formik, Form } from 'formik'
import Button from 'react-bootstrap/Button'
import { pick } from 'lodash'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'

import { confirm } from 'components/molecules/Confirmation'
import DataTable from 'components/atoms/DataTable'
import {
  useGetLabAllDmePayersQuery,
  useLinkDmePayerToLabPayerMutation,
  useLabModifyPayerMutation,
} from 'store/services/payer'
import { TableWrapper } from 'components/atoms/TableWrapper'
import DashboardContent from 'components/lab/atoms/DashboardContent'
import ButtonNew from 'components/atoms/ButtonNew'
import Input from 'components/atoms/Input'
import { handleChange } from 'components/atoms/Upsert'
import routes from '../constants/routes'
import { UpdatePayorConfirmation } from '../orderEdit/payor/UpdatePayorConfirmation'
import { PayerFormValues, PayerForm } from 'components/organisms/payer/PayerForm'
import { payorValidation } from 'components/organisms/payer/PayerDetailsModal'
import { LinkLabPayerModal } from 'components/lab/orderEdit/payor/LinkLabPayerModal'
import { useGetLabOfficesQuery } from 'store/services/office'

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const PAGE_SIZE = 20

const DmePayer = () => {
  const [values, setValues] = useState({ searchName: '', officeId: undefined })
  const [selectedPayor, setSelectedPayor] = useState<
    (PayerFormValues & { id: number; labPayerId?: number }) | null
  >(null)
  const [currentPage, setCurrentPage] = useState(0)
  const { data: offices } = useGetLabOfficesQuery({})
  const [linkLabPayerModal, setLinkLabPayerModal] = useState<{
    name: string
    id: number
    labPayerId?: number
  }>()
  // const [showUpdatePayorConfirmation, setShowUpdatePayorConfirmation] = useState(false)
  const {
    data: payers,
    isLoading,
    refetch,
  } = useGetLabAllDmePayersQuery({
    name: values.searchName,
    page: currentPage,
    size: PAGE_SIZE,
    officeId: values.officeId,
  })
  const [linkDmePayerToLabPayer] = useLinkDmePayerToLabPayerMutation()
  const [updateDmePayer] = useLabModifyPayerMutation()

  const officesOptions = useMemo(
    () =>
      offices?.map((o) => ({
        value: o.id,
        label: `${o.officeName} (${o.addressCity}, ${o.addressState})`,
      })),
    [offices],
  )

  // const onSave = async (values: LabPayerFormValues & { changeNote: string }) => {
  //   if (selectedPayor && selectedPayor > 0) {
  //     await updateLabPayer({ id: selectedPayor, ...values })
  //   } else if (selectedPayor === 0) {
  //     await createLabPayer(values)
  //   }
  //   setSelectedPayor(null)
  //   setShowUpdatePayorConfirmation(false)
  // }

  const header = [
    {
      accessor: 'id',
      to: `${routes.index}${routes.payer.root}${routes.upsert}`,
      Header: 'ID',
    },
    { accessor: 'name', Header: 'DME Payor' },
    {
      accessor: 'labPayorName',
      Header: 'Lab Payor Name (ID)',
      Cell: ({ row }) =>
        row.original.labPayerId ? (
          <>
            {row.original.labPayerName ?? 'N/A'} ({row.original.labPayerId})
          </>
        ) : (
          <></>
        ),
    },
  ]

  return (
    <DashboardContent
      title="Payors"
      icon="fa fas fa-shield-alt"
      content={
        <div className="row">
          <LinkLabPayerModal
            open={!!linkLabPayerModal}
            dmePayorName={linkLabPayerModal?.name}
            labPayorId={linkLabPayerModal?.labPayerId}
            onClose={() => setLinkLabPayerModal(undefined)}
            onSubmit={async (labPayerId: number) => {
              await linkDmePayerToLabPayer({
                orderId: 0,
                dmePayerId: selectedPayor?.id!,
                labPayerId,
              }).unwrap()
              setSelectedPayor((p) => ({ ...p, labPayerId }))
              refetch()
              setLinkLabPayerModal(undefined)
            }}
          />
          <div className="col-6">
            <div className="card card-primary">
              <form>
                <div className="card-header d-flex" css={{ '& .form-control': { flex: 2 } }}>
                  <Input
                    className="form-control mr-2"
                    autoFocus={true}
                    value={values.searchName}
                    placeholder="Search Name..."
                    name="searchName"
                    parentDiv={null}
                    handleChange={(event) => {
                      handleChange(event, values, setValues)
                    }}
                  />
                  <ReactSelect
                    className="basic-single"
                    css={{
                      flex: 1,
                      '& .select__control': {
                        minWidth: 200,
                        height: 34,
                        minHeight: 'unset',
                      },
                    }}
                    classNamePrefix="select"
                    isClearable
                    isSearchable
                    placeholder="Select DME Office"
                    name="officeId"
                    onChange={(item) => setValues({ ...values, officeId: item?.value })}
                    options={officesOptions}
                    value={officesOptions?.find((o) => o.value === values.officeId) ?? null}
                  />
                </div>
                <div className="card-body align-items-center">
                  <StyledTableWrapper className="card-body row">
                    <DataTable
                      tableKey={`lab_dme_payors`}
                      data={payers}
                      status={isLoading}
                      tableColumns={header}
                      pageSize={PAGE_SIZE}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setSortOptions={() => {}}
                      entityName="payors"
                      highlightRowId={selectedPayor?.id}
                      onRowClick={(row) =>
                        setSelectedPayor({
                          ...pick(row.original, ['id', 'name', 'labPayerId']),
                          ...row.original.address,
                          telephone: row.original.phone?.replace(/-/g, ''),
                          fax: row.original.fax?.replace(/-/g, ''),
                        })
                      }
                    />
                  </StyledTableWrapper>
                </div>
              </form>
            </div>
          </div>
          <div className="col-6">
            <div className="card card-primary">
              <div className="card-header" css={{ backgroundColor: '#4a89b3 !important' }}>
                <h3 className="card-title">Payor Details</h3>
              </div>
              <div className="card-body">
                {selectedPayor ? (
                  <Formik
                    enableReinitialize={true}
                    validationSchema={payorValidation}
                    initialValues={selectedPayor}
                    // onSubmit={() => setShowUpdatePayorConfirmation(true)}
                    onSubmit={async (values) => {
                      try {
                        confirm('', {
                          title: 'Are you sure?',
                          description: 'Are you sure you want to update this payor?',
                          yesAction: 'Yes',
                        })
                          .then(async () => {
                            await updateDmePayer(values).unwrap()
                            toast.success('Payor saved successfully')
                            refetch()
                          })
                          .catch(() => {})
                      } catch {
                        toast.error('Payor could not be saved')
                      }
                    }}>
                    {({ isValid }) => (
                      <Form>
                        <PayerForm />
                        <div className="d-flex justify-content-between mt-2">
                          <Button
                            variant="warning"
                            onClick={() =>
                              setLinkLabPayerModal({
                                name: selectedPayor.name,
                                id: selectedPayor.id,
                                labPayerId: selectedPayor.labPayerId,
                              })
                            }>
                            {selectedPayor.labPayerId && 'Modify'} Link
                          </Button>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="outline-secondary"
                              onClick={() => setSelectedPayor(null)}>
                              Cancel
                            </Button>
                            <Button type="submit" disabled={!isValid} className="ml-3">
                              Confirm
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div>Select a payor to view details</div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      breadcrumb={[{ label: 'Payors' }]}
    />
  )
}

export default DmePayer
