/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'
import { confirm } from 'components/molecules/Confirmation'
import * as generalHelper from 'helpers/generalHelper'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { keyBy } from 'lodash'
import moment from 'moment'
import { useMemo, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGetDeviceBrandsQuery, useGetDmeDevicesQuery } from 'store/services/devices'
import { useLazyGetTestTypesQuery } from 'store/services/testTypes'
import { setCurrentOfficeTests, switchOffice } from 'store/slice/initSlice'
import { setModal } from 'store/slice/modalSlice'
import ButtonModal from '../../atoms/ButtonModal'
import DashboardContent from '../atoms/DashboardContent'
import routes from '../constants/routes'

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const DeviceDirectory = () => {
  const navigate = useNavigate()
  const { userType } = useAppSelector((state) => state.init) || {}
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [sortOptions, setSortOptions] = useState<{ id: string; desc: boolean }>()
  const dispatch = useAppDispatch()
  const officeId = useAppSelector((state) => state.init.currentOfficeId)
  const [getTestTypes] = useLazyGetTestTypesQuery()
  const { data: brands } = useGetDeviceBrandsQuery({ userType })

  const { data: devices, isLoading } = useGetDmeDevicesQuery({
    officeId,
    page: currentPage,
    size: pageSize,
    sortField: sortOptions?.id,
    sortOrder: sortOptions?.desc ? 'desc' : 'asc',
  })
  const modalRef = useRef<HTMLButtonElement>(null)

  const brandsLookup = useMemo(() => {
    if (!brands?.items) return {}
    return keyBy(brands.items, 'id')
  }, [brands])

  const deviceList = useMemo(() => {
    if (!devices?.items) return devices

    return {
      ...devices,
      items: devices.items.map((device) => ({
        ...brandsLookup[device.brandId],
        ...device,
        updatedAt: device.updatedAt?.slice(0, 10),
      })),
    }
  }, [devices, brandsLookup])

  const changeLocation = async (officeId: number, route: string) => {
    try {
      await confirm('', {
        title: 'Switch Office?',
        description:
          'This order is not assigned to your office. Would you like to switch to the office that owns this order?',
        yesAction: 'Yes',
        noAction: 'No',
      })

      sessionStorage.removeItem('ts-dme-menu')
      dispatch(switchOffice(officeId))
      const testTypes = await getTestTypes({ officeId: officeId.toString() })
      dispatch(setCurrentOfficeTests(testTypes.data))

      navigate(route)
    } catch {
      console.log('no')
    }
  }

  const header = [
    {
      accessor: 'id',
      to: `${routes.index}${routes.device.root}${routes.upsert}`,
      Header: 'ID',
    },
    { accessor: 'brandId', Header: 'Type', Cell: ({ row }) => row.original.name ?? '' },
    { accessor: 'serial', key: 'serial', Header: 'Serial' },
    { accessor: 'label', Header: 'Label' },
    // { accessor: "state", key2: "name", Header: "Status" },
    // {
    //   accessor: "statusUpdatedAt",
    //   Header: "Status Date",
    //   Cell: ({ row }) => {
    //     if (!row.original.statusUpdatedAt) return null
    //     return moment(row.original.statusUpdatedAt).format('MM/DD/YYYY')
    //   }
    // },
    {
      Header: 'Assigned To Order',
      disableSortBy: true,
      accessor: 'order',
      Cell: ({ row }) => {
        if (!row.original.order) return '-'
        return (
          <>
            <Link
              onClick={() => {
                const url = `${routes.index}${routes.order.edit}${row.original.order.id}/summary`
                if (row.original.order.officeId !== Number(officeId)) {
                  changeLocation(row.original.order.officeId, url)
                }
              }}
              to={
                row.original.order.officeId === Number(officeId)
                  ? `${routes.index}${routes.order.edit}${row.original.order.id}/summary`
                  : ''
              }>
              <span css={{ fontSize: '14px !important' }}>
                {row.original.order.patientName} (#{row.original.order.id})
              </span>
            </Link>
            <br />
            {row.original.order.officeId !== Number(officeId) &&
              `(${row.original.order.officeName})`}
          </>
        )
      },
    },
    {
      Header: 'Checkout Date',
      disableSortBy: true,
      accessor: 'checkoutDate',
      Cell: ({ row }) =>
        row.original.order?.deviceAssignedAt
          ? moment(row.original.order.deviceAssignedAt).format('MM/DD/YYYY')
          : null,
    },
    {
      Header: 'Previous Order',
      disableSortBy: true,
      accessor: 'previousOrder',
      Cell: ({ row }) => {
        if (!row.original.previousOrder) return '-'
        return (
          <span css={{ fontSize: '14px !important' }}>
            {row.original.previousOrder.patientName}
            <br />
            {row.original.previousOrder.officeId !== Number(officeId) &&
              `(${row.original.previousOrder.officeName})`}
          </span>
        )
      },
    },
    // {
    //   Header: 'Status',
    //   disableSortBy: true,
    //   accessor: 'status',
    //   Cell: ({ row }) => (
    //     <span css={{ fontSize: '14px !important' }}>
    //       {startCase(row.original.state?.toLowerCase().replace(/_/g, ' '))}
    //     </span>
    //   ),
    // },
    // {
    //   accessor: 'notes',
    //   Header: 'Notes',
    //   Cell: ({ row }) =>
    //     row.original.notes ? (
    //       <PopoverWrapper
    //         placement="left"
    //         customHeader="Notes"
    //         customContent={<div className="p-2">{row.original.notes}</div>}>
    //         <span>
    //           <i className="fa fa-sticky-note" css={{ color: 'rgb(254,200,0)' }} />
    //         </span>
    //       </PopoverWrapper>
    //     ) : (
    //       <></>
    //     ),
    // },
    // { accessor: "createdAt", Header: "Date Added", Cell: ({ row }) => moment(row.original.createdAt).format('MM/DD/YYYY') },
    // { accessor: "updated", Header: "Last Updated", Cell: ({ row }) => moment(row.original.updatedAt).format('MM/DD/YYYY') },
    // {
    //   accessor: 'belongsToDme',
    //   Header: 'DME Owned',
    //   Cell: ({ row }) => (row.original.belongsToDme ? 'Yes' : '-'),
    // },
    {
      accessor: 'actions',
      Header: 'Action',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          {row.original.belongsToDme ? (
            <Link
              to={`#`}
              onClick={() => {
                dispatch(setModal(row.original))
                modalRef.current?.click()
              }}>
              <i className="fa fa-edit" />
            </Link>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ]

  return (
    <DashboardContent
      title={generalHelper.t('DEVICE MANAGEMENT')}
      subtitle="Active Devices"
      // subtitleExtra={getSubtitleExtra()}
      titleActionButton={
        <ButtonModal
          ref={modalRef}
          label="Add Device"
          className={`nav-link btn btn-primary btn-md text-white text-nowrap`}
          type="button"
          data_toggle="modal"
          data_target="#new_device_modal"
          onClick={() => {}}
        />
      }
      content={
        <div className="card card-primary">
          <div className="card-body row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group">
                    {/* <Input
                      value={values.device}
                      label={false}
                      placeholder="Search"
                      name="device"
                      parentDiv={null}
                      handleChange={event => {
                        handleChange(event, values, setValues)
                      }}
                    /> */}
                  </div>
                </div>
                {/* <div className="col-sm-3 col-md-3 pt-2">
                  <div className="form-check">
                    <Input
                      value={values.assigned_devices}
                      label={false}
                      type="checkbox"
                      name="assigned_devices"
                      className="form-check-input"
                      handleChange={event => {
                        handleChange(event, values, setValues)
                      }}
                    />
                    <label
                      htmlFor="assigned_devices"
                      className="form-check-label"
                    >
                      {generalHelper.t('Only Assigned Devices')}
                    </label>
                  </div>
                </div> */}
                <div className="col-sm-3 col-md-3">
                  {/* <Button
                    label="Search"
                    className="btn btn-primary mr-1"
                    onClick={handleSearch}
                  />
                  <Button
                    label="Clear"
                    className="btn btn-default"
                    onClick={() => {
                      setValues(defaultValues)
                    }}
                  /> */}
                </div>
              </div>
            </div>

            <StyledTableWrapper className="card-body row">
              <DataTable
                tableKey={'dme_device'}
                data={deviceList}
                status={isLoading}
                tableColumns={header}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setSortOptions={setSortOptions}
                entityName="devices"
              />
            </StyledTableWrapper>
          </div>
        </div>
      }
      breadcrumb={[
        { label: 'Home', to: `${routes.index}` },
        {
          label: `${generalHelper.t('Device Management')}`,
        },
        {
          label: 'Active Devices',
          to: `${routes.index}${routes.device.deviceDirectory}`,
        },
      ]}
    />
  )
}

export default DeviceDirectory
