/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'
import DashboardContent from '../atoms/DashboardContent'
import { useGetCompanyListQuery } from 'store/services/office'
import ButtonNew from '../../atoms/ButtonNew'
import Input from '../../atoms/Input'
import routes from '../constants/routes'

const StyledTableWrapper = styled(TableWrapper)`
  max-height: calc(100vh - 230px);
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const PAGE_SIZE = 20

const Companies = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const { data: companies, isLoading } = useGetCompanyListQuery({
    ...(search && { name: search }),
    page: currentPage,
    size: PAGE_SIZE,
  })
  console.log('companies', companies)

  const header = [
    {
      accessor: 'id',
      Header: 'Id',
    },
    { accessor: 'companyName', Header: 'Company' },
    {
      accessor: 'address',
      Header: 'Address',
      Cell: ({ row }) => (
        <div className="d-flex flex-column">
          <span>
            {row.original.addressStreetLine1} {row.original.addressStreetLine2}
          </span>
          <span>
            {row.original.addressCity}, {row.original.addressState} {row.original.addressZip}
          </span>
        </div>
      ),
    },
    { accessor: 'regions', Header: 'Regions' },
    { accessor: 'offices', Header: 'Offices' },
  ]

  const onOpenCompany = (id: number) => {
    navigate(`${routes.index}${routes.company.root}${routes.upsert}${id}`)
  }

  return (
    <DashboardContent
      title="Companies"
      icon="fas fa-building"
      content={
        <div className="card card-primary">
          <form>
            <div className="card-header row">
              <div className="col-10 input-group mb-3">
                <Input
                  autoFocus={true}
                  value={search}
                  label={false}
                  placeholder="Search..."
                  name="search"
                  parentDiv={null}
                  handleChange={(event) => setSearch(event.target.value)}
                />

                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fas fa-search" />
                  </span>
                </div>
              </div>

              <div className="col-2 text-right">
                <ButtonNew to={`${routes.index}${routes.company.root}${routes.upsert}`} />
              </div>
            </div>
          </form>
          <div className="card-body align-items-center">
            <StyledTableWrapper className="card-body row">
              <DataTable
                tableKey={'companies'}
                data={companies}
                status={isLoading}
                tableColumns={header}
                pageSize={PAGE_SIZE}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setSortOptions={() => {}}
                entityName="companies"
                onRowClick={(row) => onOpenCompany(row.original.id)}
              />
            </StyledTableWrapper>
          </div>
        </div>
      }
      breadcrumb={[
        {
          label: 'Companies',
        },
      ]}
    />
  )
}

export default Companies
