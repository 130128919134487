/** @jsxImportSource @emotion/react */
import { memo, useEffect, useState, useMemo, ReactNode } from 'react'
import moment from 'moment'
import Button from 'react-bootstrap/Button'

import { confirm } from 'components/molecules/Confirmation'
import { t } from 'helpers/generalHelper'
import DatePickerComponent from 'components/atoms/DateTimePicker'
import CheckboxComponent from 'components/atoms/Checkbox'
import { Order, DeviceSession } from 'store/types'
import { formatDuration } from 'components/dme/atoms/SessionsTable'
import { useUpdateTaskMutation } from 'store/services/labOrder'
import { toLocalDateString } from 'helpers/format'
import 'react-datepicker/dist/react-datepicker.css'

interface IProps {
  order: Order
  title?: ReactNode
  hideSubmit?: boolean
  onStartTimesChange?: (times: ({ timeStarted: string; timeStopped: string } | null)[]) => void
  onSessionSelectionChange?: (selection: number[]) => void
}

const SessionEditor = memo(
  ({ order, title, hideSubmit, onStartTimesChange, onSessionSelectionChange }: IProps) => {
    const [startTimes, setStartTimes] = useState<Date[][]>([])
    const [sessionSelections, setSessionSelections] = useState<number[]>([])
    const [updateTask] = useUpdateTaskMutation()

    const getNewSessionSelection = () =>
      order?.selectedSessions?.map((i, idx) => {
        if (sessionSelections.includes(idx)) return -1
        return i
      })

    useEffect(() => {
      if (!onStartTimesChange || !order || !startTimes?.length) return
      onStartTimesChange(getTimeStampOverrides())
    }, [startTimes])

    useEffect(() => {
      if (
        !onSessionSelectionChange ||
        !order.selectedSessions?.length ||
        !sessionSelections?.length
      )
        return

      onSessionSelectionChange(getNewSessionSelection()!)
    }, [sessionSelections])

    const relevantTask = useMemo(
      () =>
        order?.tasks?.find((task) => task?.namespace === 'DEVICE_DATA_PROBLEM' && !task.resolved),
      [order],
    )

    const series = order.selectedSessions
      ?.reduce((agg: DeviceSession[][], night, sessionIdx) => {
        if (night !== -1) {
          agg[night] = agg[night] || []
          agg[night].push({
            ...order.sessions!.sessions[sessionIdx],
            originalIdx: sessionIdx,
          })
        }
        return agg
      }, [])
      .filter((night) => night)

    useEffect(() => {
      if (!order || !series) return
      const sessionStartTimes = series.map((sessions, idx) => {
        return sessions.map((session) => {
          const timeStarted =
            relevantTask?.timestampOverrides?.[session?.originalIdx]?.startTime ||
            relevantTask?.timestampOverrides?.[session?.originalIdx]?.timeStarted
          if (timeStarted) {
              const d = new Date(timeStarted)
              d.setTime(d.getTime() + (d.getTimezoneOffset() * 60000))
              return d
          }
          const d = new Date(session.timeStarted)
          d.setTime(d.getTime() + (d.getTimezoneOffset() * 60000))
          return d
        })
      })

      setStartTimes(sessionStartTimes)
    }, [order])

    const getTimeStampOverrides = (): ({ timeStarted: string; timeStopped: string } | null)[] => {
      let currNight: number, nightIdx: number
      const timestampOverrides = order.selectedSessions!.map((night, idx) => {
        if (night === -1) return null
        nightIdx = currNight !== night ? 0 : nightIdx + 1
        currNight = night
        const timeStarted = startTimes[night - 1][nightIdx]

        return {
          timeStarted: toLocalDateString(timeStarted.toISOString()),
          timeStopped: toLocalDateString(
            moment(timeStarted)
              .add(order?.sessions?.sessions[idx].duration, 'seconds')
              .toISOString(),
          ),
        }
      })
      return timestampOverrides
    }

    const onSubmit = () => {
      updateTask({
        orderId: Number(order?.id),
        id: relevantTask?.id,
        resolved: true,
        timestampOverrides: getTimeStampOverrides(),
        ...(sessionSelections?.length && { sessions: getNewSessionSelection() }),
      })
    }

    const nightConditions = order.tests?.map((night) => {
      if (night.oxygen === 0) return 'On Room Air'
      if (night.oxygen === 1) return 'On Oxygen'
      return ''
    })

    if (!series?.length) {
      return (
        <div
          className="w-100 mb-2 ml-2 p-3"
          css={{
            background: 'white',
            borderRadius: '5px',
            border: '0.6px solid rgb(200, 200, 200)',
          }}>
          <div className="text-muted">
            There appears to be an issue with this order. There are session data problems but no
            sessions selected to edit.
          </div>
        </div>
      )
    }

    return (
      <div
        className="w-100 mb-2 ml-2 p-3"
        css={{
          background: 'white',
          borderRadius: '5px',
          border: '0.6px solid rgb(200, 200, 200)',
        }}>
        {title || null}
        {!hideSubmit && (
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-danger"
              className="mr-2"
              onClick={() => {
                confirm('', {
                  title: 'Are you sure?',
                  description: 'This will permanently delete all device data.',
                  yesAction: 'Delete',
                })
              }}>
              Delete All Device Data
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                confirm('', {
                  title: 'Override Session Data',
                  description: 'Are you sure you want to override the session data?',
                  yesAction: 'Yes',
                })
                  .then(onSubmit)
                  .catch()
              }>
              Submit
            </Button>
          </div>
        )}
        <div className="px-2">
          {series?.map((sessions, night) => (
            <div key={`night-${night}`} className="mt-2 position-relative">
              <div className="d-flex justify-content-between">
                <strong className="my-2">
                  {moment(startTimes?.[night]?.[0]).format('MM/DD/YYYY')}:{' '}
                  {nightConditions?.[night]}
                </strong>
              </div>

              <div
                className="row flex-row flex-nowrap justify-content-between align-items-center mt-1 p-2 mt-2"
                style={{
                  color: '#6D757E',
                  borderBottom: '1px solid #ced4da',
                }}>
                <div className="flex-shrink-0" style={{ width: 70 }}>
                  {t('Session')}
                </div>
                <div style={{ width: 520 }}>{t('Testing Started')}</div>
                <div style={{ width: 500 }}>{t('Testing Stopped')}</div>
                <div style={{ width: '100%' }}>{t('Duration')}</div>
                <div>Discard</div>
              </div>
              {sessions.map((session, sessionIdx) => (
                <div
                  key={`session-${sessionIdx}`}
                  className="row flex-row flex-nowrap justify-content-between align-items-center my-1 p-2"
                  style={{
                    border: '1px solid #ced4da',
                    borderRadius: 4,
                    ...(!session.isValid && { opacity: 0.5 }),
                  }}>
                  <div className="flex-shrink-0" style={{ width: 70 }}>
                    <div
                      style={{
                        width: 24,
                        textAlign: 'center',
                        border: '1px solid #2C83BD',
                        borderRadius: 4,
                        color: '#2C83BD',
                      }}>
                      {sessionIdx + 1}
                    </div>
                  </div>
                  <div style={{ width: 520 }}>
                    <div
                      css={{
                        maxWidth: 180,
                        '& .react-datepicker__time-container--with-today-button': {
                          right: '-85px !important',
                        },
                      }}>
                      <DatePickerComponent
                        dateFormat="MM/dd/yyyy hh:mm a"
                        minDate={moment().subtract(90, 'days').toDate()}
                        maxDate={moment().toDate()}
                        value={startTimes?.[night]?.[sessionIdx]}
                        todayButton="Today"
                        timeIntervals={1}
                        handleChange={(date) =>
                          setStartTimes((p) => {
                            const newVal: Date[][] = [...p]
                            newVal[night].forEach((s, idx: number) => {
                              if (idx === 0) {
                                newVal[night][idx] = date
                              } else {
                                const prevTimestamp = newVal[night][idx - 1]
                                const timeDelta = (sessions[idx].timeDelta - sessions[idx - 1].timeDelta)
                                const newDate = new Date(prevTimestamp)
                                newDate.setTime(newDate.getTime() + (timeDelta * 1000))
                                newVal[night][idx] = newDate
                              }
                            })

                            return newVal
                          })
                        }
                        disabled={sessionIdx > 0}
                      />
                      <span className="text-gray" css={{ fontSize: '12px' }}>
                        Device Data:{' '}
                        {moment(order?.sessions?.sessions[session.originalIdx].timeStarted).format(
                          'MM/DD/YYYY hh:mm a',
                        )}
                      </span>
                    </div>
                    {/* {moment(session.timeStarted).format('MM/DD/YYYY h:mma')} */}
                    {/* {anomalies[seriesIdx][sessionIdx] && (
                  <i
                    className="fas fa-exclamation-triangle text-warning"
                    style={{ marginLeft: 10 }}
                  />
                )} */}
                  </div>
                  <div style={{ width: 500 }}>
                    {moment(startTimes?.[night]?.[sessionIdx])
                      .add(session.duration, 'seconds')
                      .format('MM/DD/YYYY h:mma')}
                  </div>
                  <div style={{ width: '100%' }}>
                    {formatDuration(session.duration)}
                    {!session.isValid && ' (invalid session)'}
                  </div>
                  <div>
                    <CheckboxComponent
                      handleChange={(e) => {
                        if (e.target.checked) {
                          setSessionSelections((p) => [...p, session.originalIdx])
                        } else {
                          setSessionSelections((p) => p.filter((i) => i !== session.originalIdx))
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  },
)

export default SessionEditor
