/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { Field, Form, Formik } from 'formik'
import Toast from 'components/atoms/Toast'
import * as Yup from 'yup'
import styled from '@emotion/styled'

import { confirm } from 'components/molecules/Confirmation'
import {
  useCreatePhysicianGroupMutation,
  useGetPhysicianGroupPhysiciansQuery,
  useGetPhysicianGroupQuery,
  useUpdatePhysicianGroupMutation,
} from 'store/services/physician'
import DashboardContent from '../atoms/DashboardContent'
import states from 'constants/states'
import MaskInput from 'components/atoms/MaskInput'
import GoogleAutocomplete from 'components/atoms/GoogleAutocomplete'
import { phoneNumberMask, zipMask } from 'helpers/masks'
import { useGetLabUsersQuery, useGetPhysicianGroupUsersQuery } from 'store/services/users'
import DataTable from 'components/atoms/DataTable'
import { getAddressFields2 } from 'components/dme/forms/utils/userFormUtils'
import * as generalHelper from 'helpers/generalHelper'
import routes from '../constants/routes'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { TableWrapper } from 'components/atoms/TableWrapper'
import { requiredPhoneValidation } from 'helpers/validate'

const PAGE_SIZE = 10

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.2rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`
const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40px;
  justify-content: center;
  > div {
    white-space: nowrap;
    display: flex;
  }
`

const validation = Yup.object().shape({
  groupName: Yup.string().required('Required'),
  telephone: requiredPhoneValidation,
  fax: requiredPhoneValidation,
  npi: Yup.string()
    .optional()
    .test('len', 'Must be exactly 10 characters', (val?: string) => !val || val.length === 10),
  addressStreetLine1: Yup.string().required('Required'),
  addressCity: Yup.string().required('Required'),
  addressState: Yup.string().required('Required'),
  addressZip: Yup.string().required('Required'),
})

interface FormValues {
  groupName: string
  telephone: string
  fax: string
  npi: string
  physicians: { name: string; npi: string }[]
  addressStreetLine1: string
  addressStreetLine2: string
  addressCity: string
  addressState: string
  addressZip: string
  address: string
  physicianName: string
  physicianNpi: string
}

const initialValues: FormValues = {
  groupName: '',
  telephone: '',
  fax: '',
  npi: '',
  physicians: [],
  addressStreetLine1: '',
  addressStreetLine2: '',
  addressCity: '',
  addressState: '',
  addressZip: '',
  address: '',
  physicianName: '',
  physicianNpi: '',
}

const PhysicianGroupUpsert = () => {
  const navigate = useNavigate()
  const { physicianGroupId } = useParams()
  const [currentPage, setCurrentPage] = useState(0)
  const [createPhysicianGroup] = useCreatePhysicianGroupMutation()
  const [updatePhysicianGroup] = useUpdatePhysicianGroupMutation()
  const { data: physicianGroupData } = useGetPhysicianGroupQuery(
    physicianGroupId ? { id: physicianGroupId } : skipToken,
  )
  const { data: physicians } = useGetPhysicianGroupPhysiciansQuery(
    physicianGroupId ? { id: physicianGroupId } : skipToken,
  )
  const { data: usersData, isLoading } = useGetPhysicianGroupUsersQuery(
    physicianGroupId
      ? {
          physicianGroup: physicianGroupId,
          size: PAGE_SIZE,
          page: currentPage,
        }
      : skipToken,
  )

  const init = useMemo(() => {
    if (!physicianGroupData || !physicians) return null

    return {
      groupName: physicianGroupData?.groupName,
      telephone: physicianGroupData?.telephone,
      fax: physicianGroupData?.fax,
      npi: physicianGroupData?.npi,
      physicians: physicians?.length > 0 ? physicians : [],
      address: physicianGroupData?.addressStreetLine1,
      addressStreetLine1: physicianGroupData?.addressStreetLine1,
      addressStreetLine2: physicianGroupData?.addressStreetLine2,
      addressCity: physicianGroupData?.addressCity,
      addressState: physicianGroupData?.addressState,
      addressZip: physicianGroupData?.addressZip,
    }
  }, [physicianGroupData, physicians])

  const usersHeader = [
    {
      Header: 'ID',
      accessor: 'userId',
      Cell: ({ row }) => row.original.id,
    },
    {
      Header: 'Name',
      accessor: 'userName',
      columnSize: 3,
      Cell: ({ row }) => (
        <>
          <Link
            to={`${routes.index}${routes.physicianGroupUsers.root}${routes.upsert}${row.original.id}`}>
            {`${_.capitalize(row.original.lastName)}${
              row.original.suffix ? ' ' + row.original.suffix : ''
            }, ${_.capitalize(row.original.firstName)} ${
              row.original.middleName ? _.capitalize(row.original.middleName) : ''
            }`}
          </Link>
        </>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div>{row.original.email}</div>
        </CellWrapper>
      ),
    },
  ]

  return (
    <div>
      <DashboardContent
        icon="fas fa-building"
        title={physicianGroupId ? 'Edit Physician Group' : 'Create Physician Group'}
        subtitle={
          <div>
            <Link to={`${routes.index}${routes.physicianGroups.root}`}>
              {`< Back to Physician Groups`}
            </Link>
          </div>
        }
        breadcrumb={[
          {
            label: 'Physician Groups',
            to: `${routes.index}${routes.physicianGroups.root}`,
          },
          { label: physicianGroupId ? 'Edit' : 'New' },
        ]}
        content={
          <>
            <div className="card-body row">
              <div className="col-md-12 col-lg-6 mb-3">
                <div className="card card-primary p-2">
                  <div
                    className="card-header"
                    css={{
                      color: 'black !important',
                      backgroundColor: 'transparent !important',
                    }}>
                    <h3 className="card-title">PHYSICIAN GROUP INFORMATION</h3>
                  </div>
                  <Formik
                    enableReinitialize={true}
                    validationSchema={validation}
                    initialValues={init ?? initialValues}
                    onSubmit={async (values) => {
                      try {
                        const { address, physicianNpi, physicianName, ...vals } = values

                        if (physicianGroupId) {
                          await updatePhysicianGroup({
                            id: Number(physicianGroupId),
                            physicians: vals.physicians || physicians,
                            ...vals,
                          }).unwrap()
                          Toast({ type: 'success', label: `Physician group updated!` })
                        } else {
                          const newPg = await createPhysicianGroup({
                            ...vals,
                          }).unwrap()
                          navigate(
                            `${routes.index}${routes.physicianGroups.root}${routes.upsert}${newPg.id}`,
                          )
                          Toast({ type: 'success', label: `Physician group created!` })
                        }
                      } catch (err) {
                        Toast({ type: 'error', label: err.data.message })
                      }
                    }}>
                    {({ values, errors, touched, handleChange, setFieldValue }) => (
                      <Form id="physicianGroupUpsert" autoComplete="one-time-code">
                        <div className={`container bg-white mb-3 px-1 p-0`}>
                          <div className={`container pt-3 pb-1`}>
                            <div className="row">
                              <div className="col-4 form-group textGray">
                                <label htmlFor="groupName">
                                  Group Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="groupName"
                                  className="form-control"
                                  placeholder="Group Name"
                                  value={values.groupName}
                                  onChange={handleChange}
                                />
                                {touched.groupName && errors.groupName && (
                                  <div className="text-danger">{errors.groupName}</div>
                                )}
                              </div>
                              <div className="col-4 form-group textGray">
                                <label htmlFor="telephone">
                                  Telephone <span className="text-danger">*</span>
                                </label>
                                <MaskInput
                                  value={values.telephone}
                                  onChange={handleChange}
                                  name="telephone"
                                  mask={phoneNumberMask}
                                  placeholder="(###) ###-####"
                                  autoComplete="off"
                                />
                                {touched.telephone && errors.telephone && (
                                  <div className="text-danger">{errors.telephone}</div>
                                )}
                              </div>
                              <div className="col-4 form-group textGray">
                                <label htmlFor="lastName">
                                  Fax
                                  <span className="text-danger">*</span>
                                </label>
                                <MaskInput
                                  value={values.fax}
                                  onChange={handleChange}
                                  name="fax"
                                  mask={phoneNumberMask}
                                  placeholder="(###) ###-####"
                                  autoComplete="off"
                                />
                                {touched.fax && errors.fax && (
                                  <div className="text-danger">{errors.fax}</div>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4 form-group textGray">
                                <label htmlFor="npi">NPI</label>
                                <Field
                                  type="text"
                                  name="npi"
                                  maxlength="10"
                                  className="form-control"
                                  placeholder="NPI"
                                  value={values.npi}
                                  onChange={handleChange}
                                />
                                {touched.npi && errors.npi && (
                                  <div className="text-danger">{errors.npi}</div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className="row mx-2 mb-2"
                            css={{
                              border: '1px solid #ccc',
                              borderRadius: '5px',
                              padding: '5px 10px',
                            }}>
                            <div className="col-6 form-group textGray mt-1">
                              <label htmlFor="npi">Physicians Info</label>
                              <div className="d-flex align-items-center">
                                <Field
                                  type="text"
                                  name="physicianName"
                                  className="form-control mr-2"
                                  placeholder="Physician Name"
                                  value={values.physicianName}
                                  onChange={handleChange}
                                />
                                <Field
                                  type="text"
                                  name="physicianNpi"
                                  className="form-control"
                                  placeholder="NPI"
                                  maxlength="10"
                                  value={values.physicianNpi}
                                  onChange={handleChange}
                                />
                                <i
                                  onClick={() => {
                                    const npi = values?.physicianNpi

                                    if (npi?.length !== 10) {
                                      Toast({
                                        type: 'error',
                                        label: 'Physician NPI must be exactly 10 characters',
                                      })
                                      return
                                    }

                                    if (values?.physicians?.some(({ npi: pnpi }) => pnpi === npi)) {
                                      Toast({
                                        type: 'error',
                                        label: 'Physician NPI must be unique',
                                      })
                                      return
                                    }

                                    if (values.physicianName) {
                                      setFieldValue('physicians', [
                                        {
                                          name: values.physicianName,
                                          npi: values.physicianNpi,
                                        },
                                        ...(values.physicians || []),
                                      ])

                                      setFieldValue('physicianName', '')
                                      setFieldValue('physicianNpi', '')
                                    }
                                  }}
                                  className="fa fa-plus-circle ml-2"
                                  aria-hidden="true"
                                  css={{
                                    cursor: 'pointer',
                                    color:
                                      values.physicianName && values.physicianNpi
                                        ? '#2c83bd'
                                        : '#ccc',
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6" css={{ overflowY: 'auto', maxHeight: 150 }}>
                              {!!values.physicians?.length && (
                                <table
                                  className="table"
                                  css={{ '& td, th': { padding: '0.25rem' } }}>
                                  <thead>
                                    <tr>
                                      <th>Physician Name</th>
                                      <th>Physician NPI</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {values.physicians.map((n) => (
                                      <tr key={n.npi}>
                                        <td>{n.name}</td>
                                        <td>{n.npi}</td>
                                        <td>
                                          <i
                                            onClick={() =>
                                              confirm(
                                                `physician ${n.name} from the physician group`,
                                              )
                                                .then(() =>
                                                  setFieldValue(
                                                    'physicians',
                                                    values.physicians?.filter(
                                                      (b) => b.npi !== n.npi,
                                                    ),
                                                  ),
                                                )
                                                .catch(() => {})
                                            }
                                            className="fa fa-times-circle ml-2"
                                            aria-hidden="true"
                                            css={{ cursor: 'pointer', color: '#2c83bd' }}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>

                          <div className={`container py-2 px-3`}>
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12 form-group textGray">
                                    <label htmlFor="addressStreetLine1">
                                      {generalHelper.t('Primary Address')}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <GoogleAutocomplete
                                      id="address"
                                      name="address"
                                      onInputChanged={(v) => setFieldValue('addressStreetLine1', v)}
                                      onChange={(place) => {
                                        const { address, city, state, zip } =
                                          getAddressFields2(place)
                                        console.log(address, city, state, zip)
                                        document.getElementById('address').value = address
                                        setFieldValue('addressStreetLine1', address)
                                        setFieldValue('addressCity', city)
                                        setFieldValue('addressState', state)
                                        setFieldValue('addressZip', zip)
                                      }}
                                    />
                                    {touched.addressStreetLine1 && errors.addressStreetLine1 ? (
                                      <div className="text-danger">{errors.addressStreetLine1}</div>
                                    ) : null}
                                  </div>
                                  <div className="col-12 form-group textGray">
                                    <Field
                                      type="text"
                                      name="addressStreetLine2"
                                      className="form-control"
                                      placeholder="Suite / Apt"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-4 form-group textGray">
                                    <label htmlFor="addressCity">
                                      City
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="addressCity"
                                      className="form-control"
                                      placeholder="City"
                                    />
                                    {touched.addressCity && errors.addressCity ? (
                                      <div className="text-danger">{errors.addressCity}</div>
                                    ) : null}
                                  </div>
                                  <div className="col-4 form-group textGray">
                                    <label htmlFor="addressState">
                                      State
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="addressState"
                                      placeholder="State">
                                      {states &&
                                        states.map((s, i) => (
                                          <option key={i} value={s.id}>
                                            {s.name}
                                          </option>
                                        ))}
                                    </Field>
                                    {touched.addressState && errors.addressState ? (
                                      <div className="text-danger">{errors.addressState}</div>
                                    ) : null}
                                  </div>
                                  <div className="col-4 form-group textGray">
                                    <label htmlFor="addressZip">
                                      Zip Code
                                      <span className="text-danger">*</span>
                                    </label>
                                    <MaskInput
                                      value={values.addressZip}
                                      onChange={handleChange}
                                      name="addressZip"
                                      mask={zipMask}
                                      placeholder="#####-####"
                                    />

                                    {touched.addressZip && errors.addressZip && (
                                      <div className="text-danger">{errors.addressZip}</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 pt-3 pr-0 border-top">
                            <div className="col-sm-12 d-flex justify-content-end">
                              <Button variant="primary" type="submit">
                                Save Changes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 mb-3">
                {physicianGroupId && (
                  <div className="card card-primary p-2">
                    <div
                      className="card-header d-flex"
                      css={{
                        color: 'black !important',
                        backgroundColor: 'transparent !important',
                        justifyContent: 'space-between',
                        '&::after': {
                          display: 'none',
                        },
                      }}>
                      <h3 className="card-title">USERS</h3>
                      <Button
                        variant="primary"
                        onClick={() =>
                          navigate(
                            `${routes.index}${routes.physicianGroupUsers.root}${routes.upsert}?physicianGroup=${physicianGroupId}`,
                          )
                        }
                        size="sm">
                        Add User
                      </Button>
                    </div>
                    <StyledTableWrapper
                      className="card-body row mt-3"
                      style={{
                        overflowY:
                          !usersData?.items?.length || usersData?.items?.length < 10
                            ? 'unset'
                            : 'auto',
                      }}>
                      <DataTable
                        tableKey={`physician_group_upsert_users`}
                        data={usersData}
                        status={isLoading}
                        tableColumns={usersHeader}
                        pageSize={PAGE_SIZE}
                        setCurrentPage={setCurrentPage}
                        setSortOptions={() => {}}
                        entityName="users"
                        onRowClick={(row) =>
                          navigate(
                            `${routes.index}${routes.physicianGroupUsers.root}${routes.upsert}${row.original.id}`,
                          )
                        }
                      />
                    </StyledTableWrapper>
                  </div>
                )}
              </div>
            </div>
          </>
        }
      />
    </div>
  )
}

export default PhysicianGroupUpsert
