import { useMemo, ChangeEvent } from 'react'
import styled from '@emotion/styled'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ReactSelect from 'react-select'
import { CircularProgress } from '@mui/material'

import Select from 'components/atoms/Select'
import Input from 'components/atoms/Input'
import InputMask from 'components/atoms/InputMask'
import { SearchParams } from './Order'
import { Physician, Offices, Payer } from 'store/types'
import Dropdown from 'components/atoms/Dropdown'
import { OrderTableHelpers } from 'hooks/useOrdersTable'

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InputWrapper = styled.div`
  flex: 1;
  input,
  select {
    height: 38px;
  }
`

const orderStateOptions = [
  { key: 'verified', value: 'Only Verified Orders' },
  { key: 'failedVerification', value: 'Only Failed Verification' },
  { key: 'aobVerified', value: 'Only AOB Verified' },
  { key: 'hasUploads', value: 'Only Orders with Uploads' },
]

const authorizationStateOptions = [
  { key: 'STARTED', value: 'Started' },
  { key: 'PENDING', value: 'Not Started' },
]

const billingStateOptions = [
  { key: 'billed', value: 'Billed' },
  { key: 'nonBilled', value: 'Non-Billed' },
  { key: 'review', value: 'Billing Review' },
  { key: 'pending', value: 'Pending to CureMD' },
]

interface IProps {
  search: SearchParams
  changeSearch: (field: string, value: string | boolean) => void
  clearSearch: () => void
  physicians: Physician[]
  payers?: Payer[]
  offices?: Offices
  tableHelpers: OrderTableHelpers
  showExport?: boolean
  physiciansLoading?: boolean
}

export const AdvancedSearch = ({
  offices,
  payers,
  search,
  changeSearch,
  clearSearch,
  tableHelpers,
  showExport = false,
  physicians,
  physiciansLoading,
}: IProps) => {
  const physicianOptions = useMemo(
    () => physicians?.map((p) => ({ value: p.npi, label: `${p.lastName}, ${p.firstName}` })),
    [physicians],
  )

  const officesOptions = useMemo(
    () =>
      offices?.map((o) => ({
        value: o.id,
        label: `${o.officeName} (${o.addressCity}, ${o.addressState})`,
      })),
    [offices],
  )
  const payersOptions = useMemo(
    () =>
      payers?.map((p) => ({
        value: p.payerId,
        label: p.name,
      })),
    [payers],
  )

  const getOrderStateFilter = () => {
    if (search.verified) {
      return 'verified'
    }
    if (search.aobVerified) {
      return 'aobVerified'
    }
    if (search.hasUploads) {
      return 'hasUploads'
    }
    if (search.failedVerification) {
      return 'failedVerification'
    }
  }

  const exportTable = async () => {
    const escape = (str) => str
    const table = document.querySelector('table#data-table')
    if (!table) return
    const headers = [].slice.call(table.getElementsByTagName('thead')[0].getElementsByTagName('th'))
    var rows = [].slice.call(table.getElementsByTagName('tbody')[0].getElementsByTagName('tr'))

    const header = headers.map((header) => escape(header.innerText)).join(',')
    const data = rows
      .map((row) =>
        [].slice
          .call(row.getElementsByTagName('td'))
          .map((col) => escape(col.innerText))
          .join(','),
      )
      .join('\n')
    const csvData = [header, data].join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'orders.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  return (
    <div className="p-2" style={{ width: '100%' }}>
      <FlexRow>
        <Button variant="link" onClick={clearSearch}>
          Clear
        </Button>
        {tableHelpers.showTestTypeFilter && (
          <ButtonGroup className="me-2" aria-label="First group">
            <Button
              active={tableHelpers.testType === 'POX_COX'}
              variant="secondary"
              onClick={() => tableHelpers.setTestType('POX_COX')}>
              POX/COX
            </Button>
            <Button
              active={tableHelpers.testType === 'HST_PSG'}
              variant="secondary"
              onClick={() => tableHelpers.setTestType('HST_PSG')}>
              HST/InLab
            </Button>
            <Button
              active={tableHelpers.testType === 'POX_COX_HST_PSG'}
              variant="secondary"
              onClick={() => tableHelpers.setTestType('POX_COX_HST_PSG')}>
              ALL
            </Button>
          </ButtonGroup>
        )}
        <div className="d-flex">
          {showExport && (
            <Button onClick={exportTable} variant="outline-primary" className="mr-3">
              <i className="fas fa-file-export mr-2"></i>
              Export
            </Button>
          )}
          <Dropdown
            initialValue={tableHelpers.pageSize}
            onClick={(v) => {
              tableHelpers.setCurrentPage(0)
              tableHelpers.setPageSize(Number(v))
            }}
            buttonTypeClass="btn-outline-primary"
            options={[
              { label: '12' },
              {
                label: '20',
              },
              {
                label: '50',
              },
              {
                label: '100',
              },
              {
                label: '200',
              },
            ]}
            additionalText="Records"
          />
        </div>
      </FlexRow>
      <div className="row mt-3" style={{ width: '100%' }}>
        <InputWrapper className="col">
          <Input
            value={search.orderId}
            placeholder="Order ID"
            label="Order ID"
            name="orderId"
            handleChange={(e) => changeSearch('orderId', e.target.value)}
          />
        </InputWrapper>
        <div className="col">
          <label className="text-gray" htmlFor="officeId">
            DME Office
          </label>
          <ReactSelect
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
            className="basic-single w-100 mr-3"
            classNamePrefix="select"
            isClearable
            isSearchable
            placeholder="Select DME Office"
            name="officeId"
            onChange={(item) => changeSearch('officeId', item?.value)}
            options={officesOptions}
            value={officesOptions?.find((o) => o.value == search.officeId) ?? null}
          />
        </div>
        <InputWrapper className="col">
          <Input
            value={search.patientFirstName}
            placeholder="Patient First Name"
            label="Patient First Name"
            name="patientFirstName"
            handleChange={(e) => changeSearch('patientFirstName', e.target.value)}
          />
        </InputWrapper>
        <InputWrapper className="col" style={{ flex: 1 }}>
          <Input
            value={search.patientLastName}
            placeholder="Patient Last Name"
            label="Patient Last Name"
            name="patientLastName"
            handleChange={(e) => changeSearch('patientLastName', e.target.value)}
          />
        </InputWrapper>
        <InputWrapper className="col" style={{ flex: 1 }}>
          <InputMask
            value={search.patientDob}
            placeholder="Patient Date of Birth"
            label="Patient Date of Birth"
            type="text"
            name="patientDob"
            mask="99/99/9999"
            onChange={(event: ChangeEvent) => {
              changeSearch('patientDob', event.target.value)
            }}
          />
        </InputWrapper>
        {['INS_VER'].includes(tableHelpers.bucket) && (
          <div className="col" style={{ flex: 1 }}>
            <label className="text-gray" htmlFor="primaryPayorId">
              Primary Payor
            </label>
            <ReactSelect
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
              className="basic-single w-100 mr-3"
              classNamePrefix="select"
              isClearable
              isSearchable
              placeholder="Select Primary Payor"
              name="primaryPayorId"
              onChange={(item) => {
                console.log(item)
                changeSearch('primaryPayorId', item?.value)
              }}
              options={payersOptions}
              value={payersOptions?.find((p) => p.value === search.primaryPayorId) ?? null}
              onInputChange={(v) => v && changeSearch('payerName', v)}
            />
          </div>
        )}
        <div className="col" style={{ flex: 1, position: 'relative' }}>
          <label className="text-gray" htmlFor="physicianNpis">
            Physician
          </label>
          <ReactSelect
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
            className="basic-single w-100 mr-3"
            classNamePrefix="select"
            isClearable
            isSearchable
            placeholder="Select Physician"
            name="physicianNpis"
            onChange={(item) => changeSearch('physicianNpis', item?.value)}
            options={physicianOptions}
            value={physicianOptions?.find((p) => p.value === search.physicianNpis) ?? null}
            onInputChange={(v) => changeSearch('physicianName', v)}
            inputValue={search.physicianName}
          />
          {physiciansLoading && (
            <div style={{ position: 'absolute', top: 0, right: '10px' }}>
              <CircularProgress size={20} />
            </div>
          )}
        </div>
        {['ALL', 'ACTIVE'].includes(tableHelpers.bucket) && (
          <InputWrapper className="col">
            <Select
              options={orderStateOptions}
              value={getOrderStateFilter()}
              defaultLabel="Select State Filter"
              label="Order State Filter"
              name="orderStateFilter"
              handleChange={(event: ChangeEvent) => {
                orderStateOptions.forEach((option) => {
                  console.log(
                    'change search',
                    event.target.value,
                    option.key === event.target.value,
                  )
                  changeSearch(option.key, option.key === event.target.value)
                })
              }}
            />
          </InputWrapper>
        )}
        {['AUTHORIZATION'].includes(tableHelpers.bucket) && (
          <InputWrapper className="col">
            <Select
              options={authorizationStateOptions}
              value={search.authorizationState}
              defaultLabel="Select Filter"
              label="Order Filter"
              name="orderStateFilter"
              handleChange={(event: ChangeEvent) => {
                changeSearch('authorizationState', event.target.value)
              }}
            />
          </InputWrapper>
        )}
        {['COMPLETED'].includes(tableHelpers.bucket) && (
          <InputWrapper className="col">
            <Select
              options={billingStateOptions}
              value={search.billingState}
              defaultLabel="Select Filter"
              label="Billing Filter"
              name="billingStateFilter"
              handleChange={(event: ChangeEvent) => {
                changeSearch('billingState', event.target.value)
              }}
            />
          </InputWrapper>
        )}
      </div>
    </div>
  )
}
