import routes from 'components/lab/constants/routes'

export const bucketColumnDefaults = {
  POXCOX: {
    RECENT: ['id', 'progress', 'messages', 'notes', 'updateDate'],
    [routes.order.pulseOxCapOx.directMessages.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.active.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.all.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.open.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.demographicVerification.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.failedFaxes.bucket]: [
      'id',
      'faxUploadedDate',
      'physician',
      'faxNumber',
      'successfulFaxes',
      'faxAttempts',
      'lastFaxAttemptDate',
    ],
    [routes.order.pulseOxCapOx.pendingFaxes.bucket]: [
      'id',
      'officeName',
      'faxUploadedDate',
      'physician',
      'faxNumber',
      'faxAttempts',
    ],
    [routes.order.pulseOxCapOx.insuranceVerification.bucket]: [
      'id',
      'firstName',
      'lastName',
      'dob',
      'orderAge',
      'isOneShip',
      'primaryPayerId',
      'primaryPayer',
      'authorizationRequired',
      'primaryPayerStatus',
      'secondaryPayerId',
      'secondaryPayer',
      'secondaryPayerStatus',
      'payerFollowUpDate',
      'tertiaryPayer',
    ],
    [routes.order.pulseOxCapOx.needAttention.bucket]: [
      'id',
      'officeName',
      'progress',
      'tasks',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.awaitingReview.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.readyToSchedule.bucket]: [
      'id',
      'officeName',
      'progress',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.pendingReturn.bucket]: [
      'id',
      'officeName',
      'progress',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.shippedBack.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.followUp.bucket]: [
      'id',
      'officeName',
      'followUpDate:TPD',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.needToShip.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.readyToShip.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.shipped.bucket]: [
      'id',
      'officeName',
      'progress',
      'shippedAt',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.delivered.bucket]: [
      'id',
      'officeName',
      'progress',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.pulseOxCapOx.completed.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'completedAt',
      'updateDate',
    ],
    [routes.order.billing.authorization.bucket]: [
      'id',
      'testType',
      'daysOfService',
      'orderCompletion',
      'primaryPayer',
      'secondaryPayer',
      'payerFollowUpDate',
      'payerNotes',
      'authorizationState',
    ],
    [routes.order.billing.billingReview.bucket]: [
      'select',
      'id',
      'testType',
      'daysOfService',
      'orderCompletion',
      'primaryPayer',
      'secondaryPayer',
      'faxedStatus',
      'payerNotes',
    ],
    [routes.order.billing.billingHold.bucket]: [
      'id',
      'testType',
      'daysOfService',
      'orderCompletion',
      'primaryPayer',
      'secondaryPayer',
      'faxedStatus',
      'payerNotes',
    ],
    [routes.order.billing.billingFailed.bucket]: [
      'select',
      'id',
      'testType',
      'daysOfService',
      'orderCompletion',
      'primaryPayer',
      'secondaryPayer',
      'faxedStatus',
      'payerNotes',
    ],
    [routes.order.billing.billingQueued.bucket]: [
      'select',
      'id',
      'testType',
      'daysOfService',
      'orderCompletion',
      'primaryPayer',
      'secondaryPayer',
      'faxedStatus',
      'payerNotes',
    ],
  },
  HST: {
    [routes.order.hst.open.bucket]: [
      'id',
      'officeName',
      'progress',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.awaitingReview.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.needAttention.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.orderVerification.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.demographicVerification.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.insuranceVerification.bucket]: [
      'id',
      'testType',
      'firstName',
      'lastName',
      'dob',
      'orderAge',
      'isOneShip',
      'primaryPayerId',
      'primaryPayer',
      'authorizationRequired',
      'primaryPayerStatus',
      'secondaryPayerId',
      'secondaryPayer',
      'secondaryPayerStatus',
      'payerFollowUpDate',
      'tertiaryPayer',
    ],
    [routes.order.hst.patientConsent.bucket]: [
      'id',
      'officeName',
      'progress',
      'followUpDate:HST_PATIENT_CONSENT',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.billing.billingReview.bucket]: [
      'select',
      'id',
      'testType',
      'daysOfService',
      'orderCompletion',
      'primaryPayer',
      'secondaryPayer',
      'faxedStatus',
      'payerNotes',
    ],
    [routes.order.hst.preAuthorization.bucket]: [
      'id',
      'testType',
      'daysOfService',
      'orderCompletion',
      'primaryPayer',
      'secondaryPayer',
      'payerFollowUpDate',
      'payerNotes',
      'authorizationState',
    ],
    [routes.order.hst.patientScheduling.bucket]: [
      'id',
      'officeName',
      'progress',
      'followUpDate:HST_PAPERWORK',
      'authorizationValidTo',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.deviceShipping.bucket]: [
      'id',
      'officeName',
      'progress',
      'followUpDate:HST',
      'authorizationValidTo',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.patientTesting.bucket]: [
      'id',
      'officeName',
      'progress',
      'authorizationValidTo',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.completed.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'completedAt',
      'updateDate',
    ],
    [routes.order.hst.pendingFaxes.bucket]: [
      'id',
      'officeName',
      'faxUploadedDate',
      'physician',
      'faxNumber',
    ],
    [routes.order.hst.all.bucket]: [
      'id',
      'hstBucket',
      'officeName',
      'progress',
      'authorizationValidTo',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.readyToSchedule.bucket]: [
      'id',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.followUp.bucket]: [
      'id',
      'officeName',
      'followUpDateNo',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.needToShip.bucket]: [
      'id',
      'officeName',
      'progress',
      'authorizationValidTo',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.readyToShip.bucket]: [
      'id',
      'officeName',
      'progress',
      'authorizationValidTo',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.shipped.bucket]: [
      'id',
      'officeName',
      'progress',
      'authorizationValidTo',
      'followUpDate:HST',
      'shippedAt',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.delivered.bucket]: [
      'id',
      'officeName',
      'authorizationValidTo',
      'followUpDate:HST',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.pendingReturn.bucket]: [
      'id',
      'officeName',
      'progress',
      'authorizationValidTo',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.hst.shippedBack.bucket]: [
      'id',
      'officeName',
      'progress',
      'authorizationValidTo',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
  },
  PSG: {
    [routes.order.psg.orderVerification.bucket]: [
      'id',
      'procedure',
      'officeName',
      'progress',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.psg.patientConsent.bucket]: [
      'id',
      'procedure',
      'officeName',
      'progress',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.psg.preAuthorization.bucket]: [
      'id',
      'procedure',
      'testType',
      'daysOfService',
      'orderCompletion',
      'primaryPayer',
      'secondaryPayer',
      'payerFollowUpDate',
      'payerNotes',
      'authorizationState',
    ],
    [routes.order.psg.patientScheduling.bucket]: [
      'id',
      'procedure',
      'officeName',
      'progress',
      'followUpDate:HST',
      'authorizationValidTo',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.psg.patientScheduled.bucket]: [
      'id',
      'procedure',
      'officeName',
      'progress',
      'authorizationValidTo',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
    [routes.order.psg.completed.bucket]: [
      'id',
      'procedure',
      'officeName',
      'progress',
      'messages',
      'notes',
      'completedAt',
      'updateDate',
    ],
    [routes.order.psg.all.bucket]: [
      'id',
      'procedure',
      'hstBucket',
      'officeName',
      'progress',
      'authorizationValidTo',
      'followUpDate:HST',
      'messages',
      'notes',
      'updateDate',
    ],
  },
}
