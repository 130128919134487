/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import moment from 'moment'
import { startCase } from 'lodash'
import { skipToken } from '@reduxjs/toolkit/query/react'

import { useGetOrderHistoryLogQuery } from 'store/services/labOrder'

interface IProps {
  orderId: number
  visible?: boolean
  context?: 'DME' | 'LAB'
}

export const OrderLogs = ({ orderId, visible, context = 'LAB' }: IProps) => {
  const { data: orderStates } = useGetOrderHistoryLogQuery(
    visible ? { orderId, context } : skipToken,
  )

  const formattedData = useMemo(
    () =>
      !orderStates
        ? []
        : [...orderStates]
            .filter(
              (i) =>
                !i.eventName.toLowerCase().includes('snapshot') &&
                i.eventName !== 'DeviceKindSelectedEvent' &&
                i.eventName !== 'ShippingDoneEvent',
            )
            .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
            .map((i) => ({
              ...i,
              originalEvent: startCase(i.eventName.replace('Event', '')),
              timestamp: moment(i.timestamp).format('MM/DD/YYYY HH:mm a'),
              patient: i.patient ? `${i.patient.firstName} ${i.patient.lastName}` : null,
              user: i.user ? `${i.user.firstName} ${i.user.lastName}` : null,
              data: i.dto,
              device: i.deviceInfo,
            })),
    [orderStates],
  )

  const renderLog = (log: any) => {
    if (
      ['OrderVerificationFinishedEvent', 'OrderVerificationStartedEvent'].includes(log.eventName) &&
      !log.user
    ) {
      return (
        <li css={{ marginBottom: 3 }}>
          <div>
            {log.timestamp}: {log.originalEvent} by AI
          </div>
        </li>
      )
    }
    if (log.eventName === 'DeviceDataUploadedEvent') {
      const entry: string[] = []
      if (log.type === 'PATIENT_MOBILE_APP') {
        entry.push('Patient uploaded device data')
      } else if (log.user) {
        entry.push(`${log.user} uploaded device data`)
      } else {
        entry.push('Device data uploaded')
      }
      switch (log.type) {
        case 'MOBILE_APP':
          entry.push('using mobile app')
          break
        case 'PATIENT_MOBILE_APP':
          entry.push('using patient mobile app')
          break
        default:
          entry.push('using desktop app')
      }
      switch (log.status) {
        case 'FAILED':
          entry.push('but the upload failed')
          entry.push(
            log.statusMessage ? `with error: ${log.statusMessage}` : 'for unknown reasons.',
          )
          break
        case 'REJECTED':
          entry.push('but the upload was rejected')
          entry.push(
            log.statusMessage ? `with error: ${log.statusMessage}` : 'for unknown reasons.',
          )
          break
        default:
          entry.push('(upload OK)')
      }
      return (
        <li css={{ marginBottom: 3 }}>
          <div>
            {log.timestamp}: {entry.join(' ')}
          </div>
        </li>
      )
    }
    if (log.eventName === 'SectionVerifiedEvent') {
      const agent = log.user ? log.user : log.dto?.by === 'synthpop' ? 'AI' : ''
      return (
        <li css={{ marginBottom: 3 }}>
          <div>
            {log.timestamp}: {agent} Marked {log.dto?.section} as {log.dto?.result}
          </div>
        </li>
      )
    }
    if (log.eventName === 'DiagnosisCodeAddedEvent')
      return (
        <li css={{ marginBottom: 3 }}>
          <div>
            {log.timestamp}: {log.user && `${log.user}`} added diag code {log.code}
          </div>
        </li>
      )
    if (log.eventName === 'OrderReportDoneEvent')
      return (
        <li css={{ marginBottom: 3 }}>
          <div>
            {log.timestamp}: {log.originalEvent} for night #{log.night}
          </div>
        </li>
      )
    if (log.eventName === 'TestSmarterFulfillmentInitiatedEvent')
      return (
        <li css={{ marginBottom: 3 }}>
          <div>
            {log.timestamp}: TestSmarter OneShip initiated by {log.user}
          </div>
        </li>
      )
    return (
      <li css={{ marginBottom: 3 }}>
        {log.timestamp}: {log.originalEvent} {log.user && `by ${log.user}`}
        {log.eventName === 'NoteAddedEvent' &&
          log.data?.sentBy?.toLowerCase() === context.toLowerCase() && (
            <div css={{ marginLeft: 20 }}>
              <div>Note: {log.data?.text}</div>
            </div>
          )}
        {log.eventName === 'MessageAddedEvent' && (
          <div css={{ marginLeft: 20 }}>
            <div>Message: {log.dto?.text}</div>
          </div>
        )}
        {log.eventName === 'DeviceAssignedEvent' && (
          <div css={{ marginLeft: 20 }}>
            <div>
              Device: {log.device?.type}, serial #: {log.device?.serial}, label: {log.device?.label}
            </div>
          </div>
        )}
        {log.eventName === 'PhysicianAssignedEvent' && (
          <div css={{ marginLeft: 20 }}>
            <div>
              Physician: {log.physician?.firstName} {log.physician?.lastName}
            </div>
          </div>
        )}
        {log.eventName === 'SinglePayerAssignedEvent' && (
          <div css={{ marginLeft: 20 }}>
            <div>Payer: {log.payer?.name}</div>
          </div>
        )}
        {(log.eventName === 'FileAddedEvent' || log.eventName === 'FileRemovedEvent') && (
          <div css={{ marginLeft: 20 }}>
            <div>
              {startCase(log.dto?.uploadType.replace('_', ' '))} File:{' '}
              {log.dto?.fileName || log.dto?.fileKey}
            </div>
          </div>
        )}
        {log.eventName === 'DeviceSessionsAddedEvent' && (
          <div css={{ marginLeft: 20 }}>
            <div>
              {log.sessionsBag?.sessions?.length} sessions from {log.sessionsBag?.deviceLabel}
            </div>
          </div>
        )}
        {log.eventName === 'DeviceSessionsSelectedEvent' && (
          <div css={{ marginLeft: 20 }}>
            <div>{log.dto?.join(', ')}</div>
          </div>
        )}
        {log.eventName === 'CopaySetEvent' && (
          <div css={{ marginLeft: 20 }}>
            <div>Amount: {log.amount}</div>
          </div>
        )}
        {log.eventName === 'AobRequestedEvent' && (
          <div css={{ marginLeft: 20 }}>
            <div>
              Requested via {log.dto.method} from {log.dto.email || log.dto.phone}
            </div>
          </div>
        )}
        {log.eventName === 'OrderReportFailedEvent' && (
          <div css={{ marginLeft: 20 }}>
            <div>{log.failure?.split('Error')?.[0]}</div>
          </div>
        )}
      </li>
    )
  }

  return (
    <div css={{ maxHeight: '70vh', overflowY: 'auto' }}>
      <ul css={{ paddingInlineStart: 20 }}>{formattedData?.map((log) => renderLog(log))}</ul>
    </div>
  )
}
