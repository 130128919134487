import { baseApi } from './baseApi'
import { Payer } from 'store/types'
import {
  CreateDmePayerDto,
  UpdateDmePayerDto,
} from 'ts-back-end/src/business/dme-payer/types/dme-payer'
import { CreatePayerDto, UpdatePayerDto } from 'ts-back-end/src/business/payer/dto/payer.dto'
import { PayersListQuery } from 'ts-back-end/src/business/payer/queries/get-payers-list.query'
import { PayersList } from 'ts-back-end/src/business/payer/dto/payers-list'

type DmePayorListRequest = {
  officeIds?: number
  size: number
  name?: string
  status?: 'assigned' | 'unassigned'
  page?: number
  sortField?: string
  sortOrder?: 'ASC' | 'DESC'
}

export const payerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPayers: builder.query<Payer[], { [key: string]: string }>({
      query: (query) => ({ url: `/dme/dme-payers`, params: query }),
    }),
    createPayer: builder.mutation<Payer, CreateDmePayerDto>({
      query: (data) => ({
        method: `POST`,
        url: `/dme/dme-payers`,
        body: data,
      }),
    }),
    modifyPayer: builder.mutation<Payer, UpdateDmePayerDto>({
      query: (data) => ({
        method: `PATCH`,
        url: `/dme/dme-payers/${data.id}`,
        body: data,
      }),
    }),
    deletePayer: builder.mutation<Payer, { id: string }>({
      query: ({ id }) => ({
        method: `DELETE`,
        url: `/dme/dme-payers/${id}`,
      }),
    }),
    linkDmePayerToLabPayer: builder.mutation<
      Payer,
      { orderId: number; dmePayerId: number; labPayerId: number }
    >({
      query: ({ dmePayerId, labPayerId }) => ({
        method: `PUT`,
        url: `/lab/dme-payers/${dmePayerId}/link`,
        body: { labPayerId, dmePayerId },
      }),
      invalidatesTags: (result, error, { orderId: id, labPayerId }) => [
        { type: 'Order', id },
        { type: 'LabDmePayers', labPayerId },
      ],
    }),
    labCreateDmePayer: builder.mutation<Payer, CreateDmePayerDto>({
      query: (data) => ({
        method: `POST`,
        url: `/lab/dme-payers`,
        body: data,
      }),
    }),
    labModifyPayer: builder.mutation<Payer, UpdateDmePayerDto>({
      query: (data) => ({
        method: `PATCH`,
        url: `/lab/dme-payers/${data.id}`,
        body: data,
      }),
    }),
    getLabPayers: builder.query<Payer[], { active: boolean /*; name: string*/ }>({ // should not use name
      query: (query) => ({ url: `/lab/payers`, params: query }),
      providesTags: ['LabPayer'],
    }),
    getLabPayerList: builder.query<PayersList, PayersListQuery>({
      query: (query) => ({ url: `/lab/payers/list`, params: query }),
      providesTags: ['LabPayerList'],
    }),
    getLabPayer: builder.query<Payer, { id: string }>({
      query: ({ id }) => ({ url: `/lab/payers/${id}` }),
      providesTags: (result, error, { id }) => [{ type: 'LabPayer', id }],
    }),
    getLabPayerDmePayers: builder.query<Payer[], { id: string }>({
      query: ({ id }) => ({ url: `/lab/payers/${id}/dme-payers` }),
      providesTags: (result, error, { id }) => [{ type: 'LabDmePayers', id }],
    }),
    getLabPayerHistory: builder.query<Payer[], { id: string }>({
      query: ({ id }) => ({ url: `/lab/payers/${id}/history` }),
      providesTags: (result, error, { id }) => [{ type: 'PayerHistory', id }],
    }),
    getLabAllDmePayers: builder.query<Payer[], DmePayorListRequest>({
      query: (query) => ({ url: `/lab/dme-payers/list`, params: query }),
    }),
    createLabPayer: builder.mutation<Payer, CreatePayerDto>({
      query: (data) => ({
        method: `POST`,
        url: `/lab/payers`,
        body: data,
      }),
      invalidatesTags: ['LabPayer'],
    }),
    updateLabPayer: builder.mutation<Payer, UpdatePayerDto>({
      query: (data) => ({
        method: `PATCH`,
        url: `/lab/payers/${data.id}`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'LabPayer', id },
        { type: 'PayerHistory', id },
        { type: 'LabPayerList' },
      ],
    }),
    addLabPayerName: builder.mutation<Payer, { payerId: number; name: string }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/payers/${params.payerId}/names`,
        body: params,
      }),
      invalidatesTags: (result, error, { payerId }) => [{ type: 'LabPayer', payerId }],
    }),
    removeLabPayerName: builder.mutation<Payer, { payerId: number; uuid: number }>({
      query: (params) => ({
        method: `DELETE`,
        url: `/lab/payers/${params.payerId}/names/${params.uuid}`,
      }),
      invalidatesTags: (result, error, { payerId }) => [{ type: 'LabPayer', payerId }],
    }),
  }),
})

export const {
  useGetPayersQuery,
  useCreatePayerMutation,
  useModifyPayerMutation,
  useDeletePayerMutation,
  useLinkDmePayerToLabPayerMutation,
  useGetLabPayersQuery,
  useGetLabPayerListQuery,
  useGetLabPayerDmePayersQuery,
  useGetLabPayerHistoryQuery,
  useGetLabAllDmePayersQuery,
  useLabCreateDmePayerMutation,
  useLabModifyPayerMutation,
  useGetLabPayerQuery,
  useCreateLabPayerMutation,
  useUpdateLabPayerMutation,
  useAddLabPayerNameMutation,
  useRemoveLabPayerNameMutation,
} = payerApi
