/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { Form, Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import moment from 'moment'
import ReactSelect from 'react-select'
import { startCase } from 'lodash'
import { toast } from 'react-toastify'

import DataTable from 'components/atoms/DataTable'
import {
  useAddLabPayerNameMutation,
  useCreateLabPayerMutation,
  useGetLabPayerDmePayersQuery,
  useGetLabPayerHistoryQuery,
  useGetLabPayerListQuery,
  useGetLabPayerQuery,
  useRemoveLabPayerNameMutation,
  useUpdateLabPayerMutation,
} from 'store/services/payer'
import Select from 'components/atoms/Select'
import { TableWrapper } from 'components/atoms/TableWrapper'
import DashboardContent from 'components/lab/atoms/DashboardContent'
import ButtonNew from 'components/atoms/ButtonNew'
import Input from 'components/atoms/Input'
import { confirm } from 'components/molecules/Confirmation'
import { handleChange } from 'components/atoms/Upsert'
import { UpdatePayorConfirmation } from '../orderEdit/payor/UpdatePayorConfirmation'
import { LabPayerForm, LabPayerFormValues } from 'components/lab/orderEdit/payor/LabPayerForm'
import { payorValidation } from 'components/lab/orderEdit/payor/LabPayorDetailsModal'
import { BILLING_LOCATION_CODES, billingLocationCodeLookup } from 'components/lab/constants/payors'

const StyledTableWrapper = styled(TableWrapper)`
  max-height: calc(100vh - 230px);
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const PAGE_SIZE = 20

const activeFilterOptions = [
  { value: undefined, label: 'Filter Active...' },
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
]
const payerIdFilterOptions = [
  { value: undefined, label: 'Filter Payor ID...' },
  { value: false, label: 'Missing Payor ID' },
  { value: true, label: 'Payor ID Present' },
]

type ChargeEntry = {
  testType: string
  cptCode: string
  charge: string
  chargeLocationCode: string // 10 | 11 | 81
  billingLocationCode: number | undefined // see payors.ts > BILLING_LOCATION_CODES
}
const initialChargeEntry = {
  testType: '',
  cptCode: '',
  charge: '',
  chargeLocationCode: '',
  billingLocationCode: undefined,
}
type Obj = { [key: string]: string }
const renderObject = (obj: Obj) =>
  Object.entries(obj).map(([key, value]) => (
    <div>
      <span css={{ fontWeight: '700', marginRight: 8 }}>{startCase(key)}:</span>
      {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
    </div>
  ))

const getDifference = (obj1: Obj, obj2: Obj) =>
  Object.fromEntries(
    Object.entries(obj2).filter(
      ([key, val]) => (key in obj1 && obj1[key] !== val) || !(key in obj1),
    ),
  )

const Payer = () => {
  const [values, setValues] = useState({
    searchName: '',
    searchId: '',
    active: true,
    hasPayerId: undefined,
  })
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedPayor, setSelectedPayor] = useState<number | null>(null)
  const [showUpdatePayorConfirmation, setShowUpdatePayorConfirmation] = useState(false)
  const [updatedPayerValues, setUpdatedPayerValues] = useState<LabPayerFormValues>()
  const [newName, setNewName] = useState('')
  const [newChargeEntry, setNewChargeEntry] = useState<ChargeEntry>(initialChargeEntry)
  const { data: payers, isLoading } = useGetLabPayerListQuery({
    name: values.searchName,
    uPayerId: values.searchId,
    active: values.active,
    hasPayerId: values.hasPayerId,
    size: PAGE_SIZE,
    page: currentPage,
  })
  const { data: payer, isLoading: isLoadingPayer } = useGetLabPayerQuery(
    selectedPayor ? { id: selectedPayor.toString() } : skipToken,
  )
  const { data: dmePayers, isLoading: isLoadingDmePayers } = useGetLabPayerDmePayersQuery(
    selectedPayor ? { id: selectedPayor.toString() } : skipToken,
  )
  const { data: payerHistory, isLoading: isLoadingPayerHistory } = useGetLabPayerHistoryQuery(
    selectedPayor ? { id: selectedPayor.toString() } : skipToken,
  )

  const [createLabPayer] = useCreateLabPayerMutation()
  const [updateLabPayer] = useUpdateLabPayerMutation()
  const [addLabPayerName] = useAddLabPayerNameMutation()
  const [removeLabPayerName] = useRemoveLabPayerNameMutation()

  useEffect(() => {
    setCurrentPage(0)
  }, [values])

  useEffect(() => {
    setNewName('')
    setNewChargeEntry(initialChargeEntry)
  }, [selectedPayor])

  const initialValues: LabPayerFormValues = useMemo(
    () =>
      selectedPayor === 0
        ? {
            name: '',
            verificationLink: '',
            telephone: '',
            ourProviderId: '',
            payorId: '',
            addressStreetLine1: '',
            addressStreetLine2: '',
            addressCity: '',
            addressState: '',
            addressZip: '',
            active: false,
            inNetwork: false,
            notes: '',
            billingLocationCode: '',
            claimFileIndicator: '',
            placeOfService: '',
          }
        : {
            name: payer?.name ?? payer?.names?.[0]?.name ?? '',
            verificationLink: payer?.verificationLink ?? '',
            telephone: payer?.telephone?.replace(/^\+1/, '') ?? '',
            ourProviderId: payer?.ourProviderId ?? '',
            uPayerId: payers?.items?.find((p) => p.id === selectedPayor)?.uPayerId ?? '',
            planId: payer?.planId || '',
            addressStreetLine1: payer?.addressStreetLine1 ?? '',
            addressStreetLine2: payer?.addressStreetLine2 ?? '',
            addressCity: payer?.addressCity ?? '',
            addressState: payer?.addressState ?? '',
            addressZip: payer?.addressZip ?? '',
            active: payer?.active ?? false,
            inNetwork: payer?.inNetwork ?? false,
            notes: payer?.notes ?? '',
            billingLocationCode: payer?.billingLocationCode ?? '',
            claimFileIndicator: payer?.claimFileIndicator ?? '',
            placeOfService: payer?.placeOfService ?? '',
          },
    [payer, selectedPayor],
  )

  const onSave = async (values: LabPayerFormValues & { updateNote: string }) => {
    try {
      if (selectedPayor && selectedPayor > 0) {
        await updateLabPayer({ id: selectedPayor, ...values }).unwrap()
      } else if (selectedPayor === 0) {
        await createLabPayer(values).unwrap()
      }
      toast.success('Payor saved successfully')
    } catch (error) {
      console.log(JSON.stringify(error))
      toast.error(`Payor could not be saved. ${error.data.message}`)
    } finally {
      setShowUpdatePayorConfirmation(false)
    }
  }

  const addCharge = async (chargeEntry: ChargeEntry) => {
    if (selectedPayor && selectedPayor > 0) {
      await updateLabPayer({
        id: selectedPayor,
        charges: [...(payer?.charges ?? []), chargeEntry],
      }).unwrap()
    }
  }

  const deleteCharge = async (testType: string) => {
    if (selectedPayor && selectedPayor > 0) {
      await updateLabPayer({
        id: selectedPayor,
        charges: payer?.charges?.filter((c) => c.testType !== testType) ?? [],
      }).unwrap()
    }
  }

  const payerData = useMemo(
    () => ({
      currentPage: payers?.currentPage ?? 0,
      items:
        payers?.items?.map((p) => ({
          ...p,
          name: p.name ?? p.names?.[0]?.name,
        })) ?? [],
      totalItems: payers?.totalItems ?? 0,
      totalPages: payers?.totalPages ?? 0,
    }),
    [payers],
  )

  const dmeTableData = useMemo(
    () => ({
      currentPage: 0,
      items:
        dmePayers?.map((p) => ({
          id: p.id,
          name: p.name,
        })) ?? [],
      totalItems: dmePayers?.length ?? 0,
      totalPages: 1,
    }),
    [dmePayers],
  )

  const chargesTableData = useMemo(
    () => ({
      currentPage: 0,
      items: payer?.charges || [],
      totalItems: payer?.charges?.length || 0,
      totalPages: 1,
    }),
    [payer?.charges],
  )

  const chargeLocation = (locationCode: string) => {
    const strLocationCode = String(locationCode)
    if (strLocationCode === '11') {
      return 'Office (11)'
    } else if (strLocationCode === '12') {
      return 'Home (12)'
    } else if (strLocationCode === '81') {
      return 'Independent Laboratory (81)'
    } else {
      return `Unknown (${strLocationCode})`
    }
  }

  const historyData = useMemo(
    () => ({
      currentPage: 0,
      items:
        payerHistory
          ?.filter((p) => p.eventName === 'PayerUpdatedEvent')
          ?.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
          ?.map((p) => ({
            timestamp: moment(p.timestamp).format('MM/DD/YYYY hh:mm A'),
            user: `${p.user?.firstName ?? ''} ${p.user?.lastName ?? ''}`,
            data: p.dto.charges
              ? {
                  ...p.dto,
                  charges: JSON.stringify(p.dto.charges, null, 2),
                }
              : p.dto,
          })) ?? [],
      totalItems: payerHistory?.length ?? 0,
      totalPages: 1,
    }),
    [payerHistory],
  )

  const header = [
    {
      accessor: 'id',
      Header: 'ID',
    },
    {
      accessor: 'uPayerId',
      Header: 'Payor ID',
      Cell: ({ row }) => <>{row.original.uPayerId ?? '-'}</>,
    },
    { accessor: 'name', Header: 'Name' },
    {
      accessor: 'active',
      Header: 'Active',
      Cell: ({ row }) => <>{row.original.active ? 'Yes' : 'No'}</>,
    },
  ]

  const historyHeader = [
    { accessor: 'timestamp', Header: 'Date' },
    { accessor: 'user', Header: 'User' },
    {
      submission: 'submission',
      Header: 'Submission',
      Cell: ({ row, rows }) => {
        const currRowId = row.id
        const prevRow = rows[Number(currRowId) + 1]
        if (!prevRow) {
          return renderObject(row.original.data)
        }
        return renderObject(getDifference(prevRow.original.data, row.original.data))
      },
    },
  ]

  return (
    <DashboardContent
      title="Payors"
      icon="fa fas fa-shield-alt"
      content={
        <div className="row">
          <UpdatePayorConfirmation
            open={showUpdatePayorConfirmation}
            onClose={() => setShowUpdatePayorConfirmation(false)}
            onSubmit={(updateNote: string) => onSave({ ...updatedPayerValues, updateNote })}
          />
          <div className="col-6">
            <div className="card card-primary">
              <form>
                <div className="card-header d-flex">
                  <div className="input-group mr-1" css={{ width: 'unset', flex: 1 }}>
                    <Input
                      autoFocus={true}
                      value={values.searchName}
                      placeholder="Search Name..."
                      name="searchName"
                      parentDiv={null}
                      handleChange={(event) => {
                        handleChange(event, values, setValues)
                      }}
                    />
                  </div>
                  <div className="input-group mr-1" css={{ width: 'unset', flex: 1 }}>
                    <Input
                      autoFocus={true}
                      value={values.searchId}
                      placeholder="Search Payor ID..."
                      name="searchId"
                      parentDiv={null}
                      handleChange={(event) => {
                        handleChange(event, values, setValues)
                      }}
                    />
                  </div>
                  <div css={{ flex: 1, maxWidth: 190 }} className="mr-1">
                    <ReactSelect
                      className="basic-single w-100 mr-3"
                      css={{ '& .select__control': { height: 34, minHeight: 'unset' } }}
                      classNamePrefix="select"
                      isClearable
                      placeholder="Filter Active"
                      name="active"
                      onChange={(item) => setValues({ ...values, active: item?.value })}
                      options={activeFilterOptions}
                      value={activeFilterOptions.find((o) => o.value === values.active)}
                    />
                  </div>
                  <div css={{ flex: 1, maxWidth: 190 }} className="mr-1">
                    <ReactSelect
                      className="basic-single w-100 mr-3"
                      css={{ '& .select__control': { height: 34, minHeight: 'unset' } }}
                      classNamePrefix="select"
                      isClearable
                      placeholder="Filter Payer ID"
                      name="hasPayerId"
                      onChange={(item) => setValues({ ...values, hasPayerId: item?.value })}
                      options={payerIdFilterOptions}
                      value={payerIdFilterOptions.find((o) => o.value === values.hasPayerId)}
                    />
                  </div>
                  <div className="text-right" css={{ minWidth: 70 }}>
                    <ButtonNew onClick={() => setSelectedPayor(0)} />
                  </div>
                </div>
                <div className="card-body align-items-center">
                  <StyledTableWrapper className="card-body row">
                    <DataTable
                      tableKey={`lab_payer_payors`}
                      data={payerData}
                      status={isLoading}
                      tableColumns={header}
                      pageSize={PAGE_SIZE}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setSortOptions={() => {}}
                      entityName="payors"
                      highlightRowId={selectedPayor}
                      onRowClick={(row) => setSelectedPayor(row.original.id)}
                    />
                  </StyledTableWrapper>
                </div>
              </form>
            </div>
          </div>
          <div className="col-6">
            <div
              className="card card-primary"
              // css={{ overflowY: 'auto', maxHeight: 'calc(100vh - 120px)' }}
            >
              <div className="card-header" css={{ backgroundColor: '#4a89b3 !important' }}>
                <h3 className="card-title">Payor Details</h3>
              </div>
              <div
                className="card-body"
                css={{
                  '& ul': {
                    borderBottom: '1px solid',
                    '& li': {
                      '& button': {
                        minWidth: '100px',
                        marginBottom: '0 !important',
                        borderBottom: '1px solid',
                        borderBottomStyle: 'none !important',
                        '& .active': { borderBottomStyle: 'unset' },
                      },
                    },
                  },
                }}>
                <Tabs defaultActiveKey="form" className="mb-3">
                  <Tab eventKey="form" title="Details">
                    {selectedPayor !== null ? (
                      <Formik
                        enableReinitialize={true}
                        validationSchema={payorValidation(!!initialValues?.uPayerId)}
                        initialValues={initialValues}
                        onSubmit={(values) => {
                          setUpdatedPayerValues(values)
                          if (selectedPayor === 0) {
                            onSave(values)
                          } else {
                            setShowUpdatePayorConfirmation(true)
                          }
                        }}>
                        {({ isValid }) => (
                          <Form>
                            <LabPayerForm />
                            <div className="d-flex justify-content-end mt-2">
                              <Button
                                variant="outline-secondary"
                                onClick={() => setSelectedPayor(null)}>
                                Cancel
                              </Button>
                              <Button type="submit" disabled={!isValid} className="ml-3">
                                Confirm
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <div>Select a payor to view details</div>
                    )}
                  </Tab>
                  <Tab
                    eventKey="charges"
                    title={`Charges (${chargesTableData?.items?.length ?? 0})`}>
                    <div
                      className="input-group mr-1 d-flex align-items-center"
                      css={{ width: 'unset', flex: 1, marginBottom: 8 }}>
                      <Select
                        value={newChargeEntry.testType}
                        name="testType"
                        wrapperClassName="m-0 mr-2"
                        handleChange={(event) => {
                          setNewChargeEntry((p) => ({
                            ...p,
                            testType: event.target.value,
                            ...(['POX', 'COX'].includes(event.target.value)
                              ? { cptCode: '94762' }
                              : { cptCode: '' }),
                            charge: '100',
                            chargeLocationCode: '11',
                          }))
                        }}
                        options={[
                          { key: 'POX', value: 'Pulse Oximetry' },
                          { key: 'COX', value: 'Capnography' },
                          { key: 'HST', value: 'Home Sleep Test' },
                        ].filter((o) => !payer?.charges?.find((i) => i.testType === o.key))}
                      />
                      <Select
                        value={newChargeEntry.cptCode}
                        name="cptCode"
                        wrapperClassName="m-0 mr-2"
                        handleChange={(event) => {
                          setNewChargeEntry((p) => ({
                            ...p,
                            cptCode: event.target.value,
                          }))
                        }}
                        options={[
                          { key: '94762', value: '94762' },
                          { key: '95800', value: '95800' },
                          { key: '95801', value: '95801' },
                          { key: '95806', value: '95806' },
                          { key: 'G0398', value: 'G0398' },
                          { key: 'G0399', value: 'G0399' },
                          { key: 'G0400', value: 'G0400' },
                        ].filter((o) =>
                          !payer?.charges?.find((i) => i.testType === o.key) &&
                          (newChargeEntry.testType === 'POX' || newChargeEntry.testType === 'COX')
                            ? ['94762'].includes(o.key)
                            : ['95800', '95801', '95806', 'G0398', 'G0399', 'G0400'].includes(
                                o.key,
                              ),
                        )}
                      />
                      <Input
                        value={newChargeEntry.charge}
                        placeholder="Charge"
                        name="charge"
                        className="form-control mr-2"
                        parentDiv={null}
                        handleChange={(event) =>
                          setNewChargeEntry((p) => ({
                            ...p,
                            charge: event.target.value,
                          }))
                        }
                      />
                      <Select
                        value={newChargeEntry.chargeLocationCode}
                        name="chargeLocationCode"
                        wrapperClassName="m-0 mr-2"
                        handleChange={(event) => {
                          setNewChargeEntry((p) => ({
                            ...p,
                            chargeLocationCode: event.target.value,
                          }))
                        }}
                        options={[
                          { key: '11', value: 'Office (11)' },
                          { key: '12', value: 'Home (12)' },
                          { key: '81', value: 'Indep. Lab (81)' },
                        ]}
                      />
                      <Select
                        value={newChargeEntry.billingLocationCode}
                        name="billingLocationCode"
                        wrapperClassName="m-0 mr-2"
                        handleChange={(event) => {
                          setNewChargeEntry((p) => ({
                            ...p,
                            billingLocationCode: Number(event.target.value),
                          }))
                        }}
                        options={BILLING_LOCATION_CODES}
                      />
                      <Button
                        disabled={
                          !newChargeEntry.cptCode ||
                          !newChargeEntry.charge ||
                          !newChargeEntry.chargeLocationCode ||
                          !newChargeEntry.testType
                        }
                        variant="primary"
                        css={{ marginLeft: 8, minWidth: 100 }}
                        onClick={async () => {
                          await addCharge(newChargeEntry)
                          setNewChargeEntry(initialChargeEntry)
                        }}>
                        Add
                      </Button>
                    </div>
                    <StyledTableWrapper className="card-body row">
                      <DataTable
                        data={chargesTableData}
                        tableKey={`lab_payer_charges`}
                        status={false}
                        tableColumns={[
                          {
                            accessor: 'testType',
                            Header: 'Test Type',
                          },
                          {
                            accessor: 'cptCode',
                            Header: 'CPT Code',
                          },
                          { accessor: 'charge', Header: 'Charge' },
                          {
                            accessor: 'chargeLocationCode',
                            Header: 'Location',
                            Cell: ({ row }) => (
                              <>{chargeLocation(row.original.chargeLocationCode)}</>
                            ),
                          },
                          {
                            accessor: 'billingLocationCode',
                            Header: 'Billing Location Code',
                            Cell: ({ row }) => (
                              <>{billingLocationCodeLookup[row.original?.billingLocationCode]}</>
                            ),
                          },
                          {
                            accessor: 'action',
                            Header: 'Action',
                            Cell: ({ row }) => (
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() => {
                                  confirm('charge configuration')
                                    .then(() => deleteCharge(row.original.testType))
                                    .catch(() => {})
                                }}>
                                Delete
                              </Button>
                            ),
                          },
                        ]}
                        pageSize={50}
                        setCurrentPage={() => {}}
                        setSortOptions={() => {}}
                        entityName="charge data"
                      />
                    </StyledTableWrapper>
                  </Tab>
                  <Tab
                    eventKey="dmePayors"
                    title={`DME Payors (${dmeTableData?.items?.length ?? 0})`}>
                    <StyledTableWrapper className="card-body row">
                      <DataTable
                        tableKey={`lab_payer_dme_payors`}
                        data={dmeTableData}
                        status={isLoadingDmePayers}
                        tableColumns={[
                          {
                            accessor: 'id',
                            Header: 'ID',
                          },
                          { accessor: 'name', Header: 'Name' },
                        ]}
                        pageSize={50}
                        setCurrentPage={() => {}}
                        setSortOptions={() => {}}
                        entityName="payors"
                      />
                    </StyledTableWrapper>
                  </Tab>
                  <Tab eventKey="history" title={`History (${historyData?.items?.length ?? 0})`}>
                    <StyledTableWrapper className="card-body row">
                      <DataTable
                        tableKey={`lab_payer_update_history`}
                        data={historyData}
                        status={isLoadingPayerHistory}
                        tableColumns={historyHeader}
                        pageSize={50}
                        setCurrentPage={() => {}}
                        setSortOptions={() => {}}
                        entityName="update history"
                      />
                    </StyledTableWrapper>
                  </Tab>
                  <Tab eventKey="names" title={`Other Names (${payer?.names?.length ?? 0})`}>
                    <div
                      className="input-group mr-1 d-flex"
                      css={{ width: 'unset', flex: 1, marginBottom: 8 }}>
                      <Input
                        autoFocus={true}
                        value={newName}
                        placeholder="New Name..."
                        name="newName"
                        parentDiv={null}
                        handleChange={(event) => setNewName(event.target.value)}
                      />
                      <Button
                        disabled={!newName}
                        variant="primary"
                        css={{ marginLeft: 8, minWidth: 100 }}
                        onClick={() =>
                          addLabPayerName({
                            payerId: payer!.id,
                            name: newName,
                          })
                            .unwrap()
                            .then(() => setNewName(''))
                        }>
                        Add
                      </Button>
                    </div>
                    <StyledTableWrapper className="card-body row">
                      <DataTable
                        tableKey={`lab_payer_other_names`}
                        data={{
                          currentPage: 0,
                          items: payer?.names ?? [],
                          totalItems: payer?.names?.length,
                          totalPages: 1,
                        }}
                        status={isLoadingPayer}
                        tableColumns={[
                          { accessor: 'name', Header: 'Name' },
                          {
                            accessor: 'active',
                            Header: 'Active',
                            Cell: ({ row }) => (row.original.active ? 'Yes' : 'No'),
                          },
                          {
                            accessor: 'Delete',
                            Cell: ({ row }) => (
                              <Button
                                size="sm"
                                variant="outline-danger"
                                onClick={() =>
                                  confirm('Name')
                                    .then(() =>
                                      removeLabPayerName({
                                        payerId: payer!.id,
                                        uuid: row.original.uuid,
                                      }),
                                    )
                                    .catch(() => {})
                                }>
                                Delete
                              </Button>
                            ),
                          },
                        ]}
                        pageSize={50}
                        setCurrentPage={() => {}}
                        setSortOptions={() => {}}
                        entityName="Other Names"
                      />
                    </StyledTableWrapper>
                  </Tab>
                  {payer?.payerNotes?.length > 1 && (
                    <Tab eventKey="notes" title="Other Notes">
                      <StyledTableWrapper className="card-body row">
                        <DataTable
                          tableKey={`lab_payer_payors`}
                          data={{
                            currentPage: 0,
                            items: payer?.payerNotes,
                            totalItems: payer?.payerNotes?.length,
                            totalPages: 1,
                          }}
                          tableColumns={[
                            { accessor: 'note', Header: 'Note' },
                            {
                              accessor: 'created',
                              Header: 'Created',
                              Cell: ({ row }) =>
                                moment(row.original.createdAt).format('MM/DD/YYYY'),
                            },
                          ]}
                          pageSize={50}
                          setCurrentPage={() => {}}
                          setSortOptions={() => {}}
                          entityName="update history"
                        />
                      </StyledTableWrapper>
                    </Tab>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      }
      breadcrumb={[{ label: 'Payors' }]}
    />
  )
}

export default Payer
