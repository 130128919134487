/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react'
import styled from '@emotion/styled'
import Button from 'react-bootstrap/Button'
import moment from 'moment'

import { TableWrapper } from 'components/atoms/TableWrapper'
import DataTable from 'components/atoms/DataTable'
import DashboardContent from 'components/lab/atoms/DashboardContent'
import { useGetIncomingFaxesQuery, useDeleteIncomingFaxMutation } from 'store/services/faxes'
import { useAttachFaxDocumentMutation } from 'store/services/labOrder'
import { UploadType } from 'store/types'
import { confirm } from 'components/molecules/Confirmation'
import { AssignDialog } from './AssignDialog'

const StyledTableWrapper = styled(TableWrapper)`
  thead {
    z-index: 1;
  }
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const IncomingFax = () => {
  const [assignDialogFaxId, setAssignDialogFaxId] = useState<string>()
  const { data: faxes, isLoading } = useGetIncomingFaxesQuery(undefined, {
    pollingInterval: 30000,
    refetchOnFocus: true,
  })
  const [attachFaxDocument] = useAttachFaxDocumentMutation()
  const [deleteFax] = useDeleteIncomingFaxMutation()

  const incoming = useMemo(
    () => ({
      page: 0,
      items:
        faxes?.map((f) => ({
          uuid: f.uuid,
          mediaUrl: f.url,
          createdAt: moment(f.createdAt).format('lll'),
        })) ?? [],
      totalItems: faxes?.length ?? 0,
      totalPages: 1,
    }),
    [faxes],
  )
  const assignFaxMediaUrl = incoming?.items?.find((f) => f.uuid === assignDialogFaxId)?.mediaUrl

  const attachToOrder = (orderId: number, faxUuid: string, uploadTypes: UploadType[]) =>
    attachFaxDocument({ orderId, faxUuid, uploadTypes, fileSource: 'fax' })

  const header = [
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <span>
          <Button variant="outline-primary" size="sm" href={row.original.mediaUrl} target="_blank">
            <i className="fa fa-eye" css={{ transform: 'translateY(2px)', marginRight: 2 }} />{' '}
            Preview
          </Button>
          <Button
            variant="outline-primary"
            size="sm"
            className="ml-2"
            onClick={() => setAssignDialogFaxId(row.original.uuid)}>
            <i className="fa fa-exchange" css={{ transform: 'translateY(2px)', marginRight: 2 }} />{' '}
            Assign To Order
          </Button>
          <Button
            variant="outline-danger"
            size="sm"
            className="ml-2"
            onClick={() =>
              confirm('fax')
                .then(() => deleteFax({ uuid: row.original.uuid }))
                .catch(() => {})
            }>
            <i className="fa fa-trash" css={{ transform: 'translateY(2px)', marginRight: 2 }} />{' '}
            Delete
          </Button>
        </span>
      ),
    },
    {
      Header: 'Date Received',
      accessor: 'createdAt',
      Cell: ({ row }) => <span>{row.original.createdAt}</span>,
    },
  ]

  return (
    <DashboardContent
      title="Incoming Forms"
      icon="fa fa-arrow-down"
      content={
        <div className="card card-primary mt-3">
          <AssignDialog
            show={assignDialogFaxId}
            onSubmit={attachToOrder}
            onClose={() => setAssignDialogFaxId('')}
            faxMediaUrl={assignFaxMediaUrl}
          />
          <div className="card-body row align-items-center">
            <StyledTableWrapper className="card-body row">
              <DataTable
                tableKey={'incoming_faxes'}
                data={incoming}
                status={isLoading}
                tableColumns={header}
                entityName="incoming faxes"
                pageSize={incoming?.items?.length}
                setSortOptions={() => {}}
              />
            </StyledTableWrapper>
          </div>
        </div>
      }
      breadcrumb={[
        {
          label: 'Incoming Forms',
        },
      ]}
    />
  )
}

export default IncomingFax
