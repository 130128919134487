/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { phoneNumberMask } from 'helpers/masks'
import { requiredPhoneValidation } from 'helpers/validate'
import MaskInput from 'components/atoms/MaskInput'
import { CheckboxInput } from 'components/atoms/Checkbox'
import { Order } from 'store/types'
import { toast } from 'react-toastify'
import { FormikDirtyStateSync } from 'components/atoms/FormikDirtyStateSync'
import DateTimePicker from 'components/atoms/DateTimePicker'
import { TimeWrapper } from 'components/lab/orderEdit/PsgHstWorkflow/FollowUpForm'

const InputWrapper = styled.div`
  input {
    max-width: 250px;
  }
`

const formValidation = Yup.object().shape({
  mobilePhone: Yup.string().when('hasNoSmartPhone', {
    is: false,
    then: () => requiredPhoneValidation,
  }),
  email: Yup.string().when('hasNoSmartPhone', {
    is: false,
    then: () => Yup.string().required(`Required`).email('Invalid Email'),
  }),
  hasSmartPhone: Yup.string().when('hasNoSmartPhone', {
    is: false,
    then: () => Yup.bool().oneOf([true], 'Must be checked'),
  }),
  smsConsent: Yup.string().when('hasNoSmartPhone', {
    is: false,
    then: () => Yup.bool().oneOf([true], 'Must be checked'),
  }),
})

interface IProps {
  order: Order
  initiateTsFulfillment: (arg: {
    orderId: number
    mobilePhone: string
    email: string
  }) => Promise<Order>
  onClose?: () => void
  setFormDirty?: (isDirty: boolean) => void
}

export const OneShipSelection = ({
  order,
  initiateTsFulfillment,
  onClose,
  setFormDirty = () => {},
}: IProps) => {
  console.log('nightowl:', onClose)
  return (
    <div className="row align-items-center px-4">
      <Formik
        enableReinitialize={true}
        validationSchema={formValidation}
        initialValues={{
          email: order?.patient?.email,
          mobilePhone: order?.patient?.mobilePhone?.replace(/^\+1/, ''),
          hasSmartPhone: !!order?.thirdPartyDevice || false,
          smsConsent: !!order?.thirdPartyDevice || false,
          testingDate: null,
          hasNoSmartPhone: false,
        }}
        onSubmit={async (values) => {
          console.log(values)
          try {
            await initiateTsFulfillment({
              orderId: Number(order?.id),
              mobilePhone: values.mobilePhone || undefined,
              email: values.email || undefined,
              hasNoSmartPhone: values.hasNoSmartPhone,
              testingDate: values.testingDate,
            })
            if (onClose) {
              onClose()
            }
          } catch (err) {
            toast.error(err?.data?.message ?? 'Unable to submit with the provided data.')
          }
        }}>
        {({ values, errors, touched, handleChange, dirty, setFieldValue }) => (
          <Form id="NightOwl" className="container-fluid">
            <FormikDirtyStateSync isDirty={dirty} setFormDirty={setFormDirty} />
            {/* <TitleLead className="my-2 text-bold mr-5" label="Patient" /> */}
            <div
              css={{
                borderRadius: '5px',
                border: '1px solid #ccc',
                padding: 16,
                marginBottom: 8,
              }}>
              <div className="row">
                <InputWrapper className="col-5 form-group textGray">
                  <label htmlFor="mobilePhone">Smart Mobile Phone Number</label>
                  {!values.hasNoSmartPhone && <span className="text-danger ml-2">(required)</span>}
                  <MaskInput
                    disabled={values.hasNoSmartPhone}
                    value={values.mobilePhone}
                    onChange={handleChange}
                    name="mobilePhone"
                    mask={phoneNumberMask}
                    placeholder="(###) ###-####"
                  />
                  {touched.mobilePhone && errors.mobilePhone ? (
                    <div className="text-danger">{errors.mobilePhone}</div>
                  ) : null}
                </InputWrapper>
                <InputWrapper className="col-5 form-group textGray">
                  <label htmlFor="mobilePhone">E-mail</label>
                  {!values.hasNoSmartPhone && <span className="text-danger ml-2">(required)</span>}
                  <Field
                    disabled={values.hasNoSmartPhone}
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={values.email}
                  />
                  {touched.email && errors.email ? (
                    <div className="text-danger">{errors.email}</div>
                  ) : null}
                </InputWrapper>
              </div>
              <Field
                disabled={values.hasNoSmartPhone}
                id="hasSmartPhone"
                name="hasSmartPhone"
                component={CheckboxInput}
                label="I have confirmed the patient has a smart phone."
                parentClassName={`custom-control my-2 ${errors.hasSmartPhone && 'text-danger'}`}
              />
              <Field
                disabled={values.hasNoSmartPhone}
                id="smsConsent"
                name="smsConsent"
                component={CheckboxInput}
                label="Patient has consented to receive text messages from TestSmarter."
                parentClassName={`custom-control my-2 ${errors.hasSmartPhone && 'text-danger'}`}
              />
            </div>
            OR
            <Field
              id="hasNoSmartPhone"
              name="hasNoSmartPhone"
              component={CheckboxInput}
              label="Patient does not have a smart phone."
              parentClassName={`custom-control my-2`}
            />
            {values.hasNoSmartPhone && (
              <div className="alert alert-warning" css={{ lineHeight: '1.5' }} role="alert">
                Patients without a smartphone will delay the testing process and a report will be
                generated when the device is returned to TestSmarter. If no data is on the device
                when returned, the order will revert to a standard order and the patient will need
                to obtain a new device from your location.
              </div>
            )}
            <hr />
            <p>
              If this order is intended for a future test date and the device shipment should be
              delayed until a later date, please specify it below:
            </p>
            <TimeWrapper
              css={{
                width: 300,
                marginTop: 16,
              }}>
              <div css={{ fontWeight: '500' }} className="mb-1">
                Testing Date (optional)
              </div>
              <DateTimePicker
                value={values.testingDate}
                handleChange={(date) => setFieldValue('testingDate', date)}
                minDate={new Date()}
                showTimeSelect={false}
                isClearable
                dateFormat="MMMM d, yyyy"
              />
            </TimeWrapper>
          </Form>
        )}
      </Formik>
    </div>
  )
}
